import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {Typography, Skeleton, Box } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ENDPOINT, } from '../../../Opportunity/Redux/oppor';
import Bounce from 'react-reveal/Bounce';

const Activity = ({ funcAlert, screenHeight }) => {

    const access_token = useSelector((state) => state.auth.user.access);
  
    const [data, setData] = useState([])
  
    data?.sort((a, b) => new Date(a.date) - new Date(b.date)).reverse();
  
    const [loading, setLoading] = useState(false)
  
    useEffect(() => {
  
      setLoading(true)
      const fetchData = async () => {
        try {
  
          const response = await fetch(`${ENDPOINT}/api/sub/account-billing-activity/`, {
            method: "GET",
            headers: {
              Authorization: `JWT ${access_token}`,
              "Content-Type": "application/json",
            },
          });
  
          const data = await response.json();
          if (!response.ok) {
            setLoading(false)
  
          } else {
            setData(data)
            setLoading(false)
  
          }
        } catch (error) {
          console.error("An error occurred:", error);
          setLoading(false)
        }
      }
      fetchData()
    }, [])
  
    return (
      <>
        {
          loading ?
            (
              <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', gap: '1rem', padding: '0rem 1rem' }}>
                <Skeleton sx={{ background: '#f0f0f0' }} variant="rounded" width={'100%'} height={80} animation={'wave'} />
                {
                  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 2, 3, 3,].map((item, ind) => {
                    return <Skeleton sx={{ background: '#f0f0f0' }} variant="rounded" width={'100%'} height={'40px'} animation={'wave'} />
                  })
                }
              </Box>
            )
            :
            <Bounce bottom>
              <>
                <TableContainer
                  sx={{
                    marginTop: '1rem',
                    maxHeight: screenHeight - 260,
                    overflow: 'auto', scrollbarWidth: "none", border: '1.5px solid lightGray', borderRadius: '8px',
                  }}
                >
                  <Table stickyHeader
                    aria-label="sticky table"
                    sx={{
                      borderRadius: "8px",
  
                    }} >
                    <TableHead
                      sx={{
                        border: '1.5px solid lightGray',
                        backgroundColor: '#f2f2f2',
                      }}
                    >
                      <TableRow sx={{
                        height: '70px',
                        border: '1.5px solid lightGray',
                        backgroundColor: '#f2f2f2',
                        borderRadius: "8px",
                      }}>
                        <TableCell
                          sx={{
                            color: 'GrayText',
                            fontSize: '15px',
                            backgroundColor: '#f2f2f2',
  
                          }}
                        >
                          Date
                        </TableCell>
                        <TableCell
                          sx={{
                            color: 'GrayText',
                            fontSize: '15px',
                            backgroundColor: '#f2f2f2',
                          }}
                        >
                          Activity
                        </TableCell>
                        <TableCell
                          sx={{
                            color: 'GrayText',
                            fontSize: '15px',
                            backgroundColor: '#f2f2f2',
                          }}
                        >  
                        </TableCell>
                        <TableCell
                          sx={{
                            color: 'GrayText',
                            fontSize: '15px',
                            backgroundColor: '#f2f2f2',
  
                          }}
                        ></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
  
                      {
                        data.map((v, i) => {
  
                          const dateObject = new Date(v.date);
                          const year = dateObject.getFullYear();
                          const day = dateObject.getDate();  
                          const dateObject1 = new Date(v.date);
  
                          // Get the month name
                          const monthName = dateObject1.toLocaleString('en-US', { month: 'long' });

                          return (
  
                            <TableRow hover>
                              <TableCell>
                                {monthName + ' ' + day + ", " + year}
                              </TableCell>
                              <TableCell
                                style={{
                                  alignItems: 'center',
                                  fontSize: '14px',
                                  color: '#585151',
                                }}
                              >Job<span style={{ color: "#711fff", fontWeight: 600 }} className='tw-text-[#711fff]'> "{v.project_title && v.project_title}" </span> {v.activity}</TableCell>
                              <TableCell sx={{ fontSize: '14px', fontWeight: '500' }}>
  
                              </TableCell>
                              <TableCell align="center">
                              </TableCell>
                            </TableRow>
                          )
                        })
                      }  
                      <TableRow>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                {
                  data.length === 0 &&
                  <Typography sx={{ my: 4, display: 'flex', justifyContent: 'center', fontWeight: 700 }}>No Activity History Found</Typography>
                }
              </>
            </Bounce>
        }
      </>
    );
  };

  export default Activity