import React from 'react';
import helpVid from '../../../pages/Opportunity/assets/Section_Help-idle.svg';
import Dots from '../../../pages/Stream/assets/Dots.png';

// eslint-disable-next-line
export function ActionBar({
  helpIconFunction,
  Dotopen,
  handleDotDropDown,
  menuDotRequired,
}) {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        background: 'inherit',
        border: 'none',
        outline: 'none',
        flexDirection: 'row',
        gap: '10px',
        padding: '0rem 1rem',
        width: '100%',
      }}
    >
      {helpIconFunction && (
        <img
          alt="help-video"
          src={helpVid}
          onClick={() => {
            if (helpIconFunction) {
              helpIconFunction();
            }
          }}
          style={{
            color: '#a6a6a6',
            marginRight: '10px',
            width: '28px',
            height: '28px',
            cursor: 'pointer',
          }}
        />
      )}
      {menuDotRequired && (
        // eslint-disable-next-line
        <img
          className="icon-sm"
          src={Dots}
          alt="dots"
          aria-controls={Dotopen ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={Dotopen ? 'true' : undefined}
          onClick={handleDotDropDown}
          style={{ cursor: 'pointer' }}
        />
      )}
    </div>
  );
}
