import { useState, useCallback } from 'react';

import { createJobNote } from '../../../api/jobs/createJobNote';

// eslint-disable-next-line import/prefer-default-export
export const useCreateJobNote = (accessToken) => {
  const [isCreatingJobNote, setIsCreatingJobNote] = useState(false);
  const [errorWhenCreatingJobNote, setErrorWhenCreatingJobNote] =
    useState(null);

  const [jobNoteResponse, setJobNoteResponse] = useState(null);

  const createNote = useCallback(
    async (jobUniqueID, noteContent) => {
      if (!accessToken || !jobUniqueID || !noteContent) {
        return;
      }

      try {
        setIsCreatingJobNote(true);

        const data = await createJobNote(accessToken, jobUniqueID, noteContent);

        setJobNoteResponse(data);
        setErrorWhenCreatingJobNote(null);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Error creating Job Note:', err);

        setErrorWhenCreatingJobNote(
          'Unable to create Job Note. Please try again later.',
        );
      } finally {
        setIsCreatingJobNote(false);

        setTimeout(() => {
          setIsCreatingJobNote(false);
        }, 3000);
      }
    },
    [accessToken],
  );

  return {
    createNote,
    isCreatingJobNote,
    errorWhenCreatingJobNote,
    jobNoteResponse,
  };
};
