import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Bounce from 'react-reveal/Bounce';

import { Link } from 'react-router-dom';

import { Box } from '@mui/system';
import { Typography, Grid } from '@mui/material';

import { appRoutes } from '../../../appRoutes';

import WorkTypes from '../../../Assets/welcomeScreen/worktypes.jpg';
import Vector from '../../../Assets/welcomeScreen/Vector.svg';
import WelcomeToNet from '../../../Assets/welcomeScreen/Welcometonet.jpg';
import ReadyForTour from '../../../Assets/welcomeScreen/Readyfortour.jpg';
import Rectangle from '../../../Assets/welcomeScreen/Rectangle.jpg';
import Rectangle1 from '../../../Assets/welcomeScreen/Rectangle1.jpg';
import Rectangle2 from '../../../Assets/welcomeScreen/Rectangle2.jpg';
import Rectangle3 from '../../../Assets/welcomeScreen/Rectangle3.jpg';
import Rectangle4 from '../../../Assets/welcomeScreen/Rectangle4.jpg';
import Rectangle5 from '../../../Assets/welcomeScreen/Rectangle5.jpg';
import Rectangle6 from '../../../Assets/welcomeScreen/Rectangle6.jpg';
import Dive from '../../../Assets/welcomeScreen/Dive.jpg';
import Dive1 from '../../../Assets/welcomeScreen/Dive1.jpg';
import Dive2 from '../../../Assets/welcomeScreen/Dive2.jpg';
import Dive3 from '../../../Assets/welcomeScreen/Dive3.jpg';
import Dive4 from '../../../Assets/welcomeScreen/Dive4.jpg';
import Dive5 from '../../../Assets/welcomeScreen/Dive5.jpg';
import Dive6 from '../../../Assets/welcomeScreen/Dive6.jpg';
import Dive7 from '../../../Assets/welcomeScreen/Dive7.jpg';
import Dive8 from '../../../Assets/welcomeScreen/Dive8.jpg';
import Dive9 from '../../../Assets/welcomeScreen/Dive9.jpg';
import Dive10 from '../../../Assets/welcomeScreen/Dive10.jpg';
import Dive11 from '../../../Assets/welcomeScreen/Dive11.jpg';
import Dive12 from '../../../Assets/welcomeScreen/Dive12.jpg';
import Dive13 from '../../../Assets/welcomeScreen/Dive13.jpg';

import { NetNetCheckBox } from '../../../Componenets/Misc';

import WelComeHelpVideo from './WelComeHelpVideo';
import WelcomeNewHelpVideo from './WelcomeNewHelpVideo';
import TitleBar from '../../../Componenets/atomic-molecules/Headers/TitleBar';

import { useRouteTo } from '../../../hooks/useRouteTo';
import { BrowserStorageManager } from '../../../cache/BrowserStorageManager';

const storageManager = new BrowserStorageManager(localStorage);

const welcomeVideos = [
  {
    img: WelcomeToNet,
    title: 'Welcome To Net Net',
    para: 'If this is your first time here, please watch this video! We’ll give you the broad strokes to get you started.',
    videoLink: 'https://videos.hellonetnet.com/watch/GKmaeMLA',
  },
  {
    img: ReadyForTour,
    title: ' Ready For A Tour?',
    para: 'If you have 10 minutes, sit back, grab a beverage and have a look at our guided tour that will take you from novice to pro in no time.',
    videoLink: 'https://videos.hellonetnet.com/watch/tFVCRofl',
  },
];

const coreConceptsVideos = [
  {
    img: WorkTypes,
    title: 'Work Types',
    para: 'What you offer to clients & how it’s priced',
    videoLink: 'https://videos.hellonetnet.com/watch/DJgg1tCw',
  },
  {
    img: Rectangle,
    title: 'Sales: Opportunities & Estimates',
    para: 'We’ve simplified sales unlike any other CRM',
    videoLink: 'https://videos.hellonetnet.com/watch/n5Y9Y4AH',
  },
  {
    img: Rectangle1,
    title: 'Time',
    para: 'Estimated, Assigned & Actual time',
    videoLink: 'https://videos.hellonetnet.com/watch/ygNPlcTB',
  },
  {
    img: Rectangle2,
    title: 'Deliverables & Tasks',
    para: 'How grouping tasks into deliverables works',
    videoLink: 'https://videos.hellonetnet.com/watch/SlwetZGk',
  },
  {
    img: Rectangle3,
    title: 'Quick Tasks vs. Job Tasks',
    para: 'Comparing the different types of tasks',
    videoLink: 'https://videos.hellonetnet.com/watch/_GCLvxjV',
  },
  {
    img: Rectangle4,
    title: 'Task Statuses',
    para: 'Your work will flow with our simple statuses',
    videoLink: 'https://videos.hellonetnet.com/watch/H9g5undd',
  },
  {
    img: Rectangle5,
    title: 'Team Access Levels',
    para: "We'll cover the role types and their capabilities",
    videoLink: 'https://videos.hellonetnet.com/watch/72J1aOEd',
  },
  {
    img: Rectangle6,
    title: 'Estimate vs. Actual for Reporting',
    para: 'This is where the juice is, the open secret about how you will scale your firm with Net Net',
    videoLink: 'https://videos.hellonetnet.com/watch/4lB3tQgO',
  },
];

const diveVideos = [
  {
    img: Dive,
    title: 'Managing Work Types',
    para: 'Work Types are the cornerstone of Net Net.  Learn how to add and modify work types in seconds.',
    videoLink: 'https://videos.hellonetnet.com/watch/7DZusKKY',
  },
  {
    img: Dive1,
    title: 'Managing Your Team',
    para: "Get your entire team on boarded into Net Net. We'll cover invitations & roles.",
    videoLink: 'https://videos.hellonetnet.com/watch/JkfCmYQr',
  },
  {
    img: Dive2,
    title: 'Adding & Importing Your Contact',
    para: 'Getting all of your contacts into Net Net is a breeze!',
    videoLink: 'https://videos.hellonetnet.com/watch/zgPV4xYt',
  },
  {
    img: Dive3,
    title: 'Creating Estimates',
    para: "Learn how to create your estimates in no time . You'll get an overview of the deliverable templates, setting LOE, pricing and more!",
    videoLink: 'https://videos.hellonetnet.com/watch/NTb8c_xP',
  },
  {
    img: Dive4,
    title: 'Activating Estimates Into Jobs',
    para: 'In 5 minutes any of your estimates can be set up and ready to go into production. Ready, set, activate!',
    videoLink: 'https://videos.hellonetnet.com/watch/USScaUJq',
  },
  {
    img: Dive5,
    title: 'Create A Job Without An Estimate',
    para: "If you have internal or external projects or retainers that your don't want to use Net Net's money part, you can still run them.",
    videoLink: 'https://videos.hellonetnet.com/watch/qCNRe1WC',
  },
  {
    img: Dive6,
    title: 'Managing Jobs',
    para: 'Project management is a breeze. Assiging and managing tasks utilizing statuses and integrated time tracking will give you insight.',
    videoLink: 'https://videos.hellonetnet.com/watch/wo5umvj3',
  },
  {
    img: Dive7,
    title: 'Quick Tasks',
    para: 'Because not everything you do internally and for clients falls in a Job, Learn how to navigate one-off and internal tasks!',
    videoLink: 'https://videos.hellonetnet.com/watch/dNJwaFNs',
  },
  {
    img: Dive8,
    title: 'Time Tracking',
    para: 'Time tracking is a breeze with Net Net. Timers and Manual Entries work equally well. Here’s your time primer!',
    videoLink: 'https://videos.hellonetnet.com/watch/rZ5q2ABW',
  },
  {
    img: Dive9,
    title: 'Utilizing Chat with Smart Mentions!',
    para: 'Sure you can @ anyone, but can you tilde ~deliverables/tasks?  Find out how chat with smart mentions ups your collaboration.',
    videoLink: 'https://videos.hellonetnet.com/watch/J6L4QHnS',
  },
  {
    img: Dive10,
    title: 'Change Orders for Your Jobs',
    para: 'Is it in scope or out?  You can issue change orders that can be added to any of your projects or retainers at any point.',
    videoLink: 'https://videos.hellonetnet.com/watch/rkELWPxg',
  },
  {
    img: Dive11,
    title: 'Job KPI’s',
    para: 'KPI’s are essential for all of your projects and retainers.  Net Net keeps them front and center so you’re in the know!',
    videoLink: 'https://videos.hellonetnet.com/watch/mrN5rbMM',
  },
  {
    img: Dive12,
    title: 'Reports',
    para: 'Slice and dice all aspects of your firm from sales, to time, to work types, team members and more.  Net Net’s promise comes down to actionable insight. This is it!',
    videoLink: 'https://videos.hellonetnet.com/watch/a4q_rNli',
  },
  {
    img: Dive13,
    title: 'Manage Billing',
    para: 'Learn more about our active Job pricing, how to set up your package and see everything about the exciting world of billing!',
    videoLink: 'https://videos.hellonetnet.com/watch/IkmhnHhQ',
  },
];

const allVideos = [
  {
    title: 'Welcome',
    videosData: welcomeVideos,
  },
  {
    title: 'Net Net Core Concepts',
    videosData: coreConceptsVideos,
  },
  {
    title: 'Dive Into Net Net',
    videosData: diveVideos,
  },
];

export default function Welcome({
  screenSize,
  screenHeight,
  updateInnerNetNetTabs,
}) {
  const currentUser = useSelector((state) => state.userData.userData) || {};
  const routeTo = useRouteTo();

  const [show, setShow] = useState(false);
  const [clickData, setClickData] = useState(null);
  const [showHelp, setShowHelp] = useState(false);
  const [countDown, setCountDown] = useState(10);

  const storageKey = `netnet-welcome-screen-${currentUser?.id}`;
  const showWelcomeInitialState = storageManager.get(storageKey) ?? true;
  const [showWelcome, setShowWelcome] = useState(showWelcomeInitialState);

  const toggleShowWelcome = () => {
    storageManager.set(storageKey, !showWelcome);

    setShowWelcome(!showWelcome);
  };

  useEffect(() => {
    if (!showWelcome) {
      const timeoutId = setTimeout(() => {
        routeTo(appRoutes.myTasks);
        updateInnerNetNetTabs('My Tasks', appRoutes.myTasks);
      }, 10000);

      const intervalId = setInterval(() => {
        setCountDown((prevCount) => {
          if (prevCount === 1) {
            clearInterval(intervalId);
            return 0;
          }
          return prevCount - 1;
        });
      }, 1000);

      return () => {
        clearTimeout(timeoutId);
        clearInterval(intervalId);
      };
    }
  }, [showWelcome]);

  return showWelcome ? (
    <Bounce left>
      <section className="oppor-section">
        <TitleBar
          sectionTitle="Welcome to Net Net!"
          buttonRequired={false}
        />
        <Grid
          container
          sx={{
            width: '100%',
            maxWidth: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'start',
            overflowY: 'auto',
            maxHeight: screenHeight - 155,
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={4.5}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              pt: 4,
              pl: 1,
              pr: 1,
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                gap: '10px',
                padding: '12px 12px',
                width: '100%',
                backgroundColor: '#E7DAFF',
                borderRadius: '15px',
              }}
            >
              {welcomeVideos.map((item) => {
                return (
                  <Box
                    key={item?.title}
                    sx={{
                      display: 'flex',
                      position: 'relative',
                      height: '150px',
                    }}
                  >
                    <div style={{ width: '240px', height: '150px' }}>
                      <img
                        alt="img"
                        src={item.img}
                        frameBorder="8"
                        style={{
                          borderRadius: '15px',
                          width: '240px',
                          height: '150px',
                        }}
                      />
                      <img
                        src={Vector}
                        style={{
                          position: 'absolute',
                          top: 50,
                          left: 100,
                          width: '40px',
                          height: '40px',
                        }}
                        alt="vector"
                      />
                    </div>

                    <Box
                      sx={{
                        background: 'none',
                        position: 'absolute',
                        width: '95%',
                        height: '150px',
                        left: '0',
                        top: '0',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setShowHelp(true);
                        setClickData(item);
                      }}
                    />
                    <div
                      style={{
                        width: '100%',
                        padding: '0px 0px 0px 12px',
                        height: '150px',
                        overflow: 'hidden',
                      }}
                    >
                      <p
                        style={{
                          fontSize: '14px',
                          fontWeight: 600,
                          color: '#000000',
                        }}
                      >
                        {item.title}
                      </p>
                      <Typography
                        variant="subtitle1"
                        color="#000000"
                        component="div"
                        sx={{
                          fontSize: { sm: '14px', md: '14px', lg: '14px' },
                          marginTop: { sm: '15px', md: '10px', lg: '15px' },
                        }}
                      >
                        {item.para}
                      </Typography>
                    </div>
                  </Box>
                );
              })}
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                width: '100%',
                overflow: 'hidden',
                backgroundColor: '#fff',
                borderRadius: '10px',
                padding: '10px 15px',
              }}
            >
              <Box
                sx={{ display: 'flex', width: '100%', marginBottom: '10px' }}
              >
                <p
                  style={{
                    fontSize: '18px',
                    fontWeight: 'bold',
                    color: '#03071E',
                  }}
                >
                  Net Net Core Concepts
                </p>
              </Box>
              {coreConceptsVideos.map((item) => {
                return (
                  <Box
                    key={item?.title}
                    sx={{
                      display: 'flex',
                      position: 'relative',
                      width: '100%',
                      overflow: 'hidden',
                      height: '70px',
                      marginBottom: '15px',
                    }}
                  >
                    <div style={{ width: '100px', height: '65px' }}>
                      <img
                        alt="img"
                        src={item.img}
                        frameBorder="8"
                        style={{
                          borderRadius: '8px',
                          width: '100px',
                          height: '65px',
                          objectPosition: 'center',
                          objectFit: 'cover',
                        }}
                      />
                      <img
                        src={Vector}
                        style={{
                          position: 'absolute',
                          top: 25,
                          left: 40,
                          width: '20px',
                          height: '20px',
                        }}
                        alt="vector"
                      />
                    </div>
                    <Box
                      sx={{
                        background: 'none',
                        position: 'absolute',
                        width: '95%',
                        height: '120px',
                        left: '0',
                        top: '0',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setShowHelp(true);
                        setClickData(item);
                      }}
                    />
                    <div style={{ width: '100%', padding: '4px 12px' }}>
                      <Typography
                        component="div"
                        variant="h5"
                        sx={{
                          fontSize: '14px',
                          fontWeight: 600,
                          color: '#000000',
                        }}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        color="#000000"
                        component="div"
                        sx={{ fontSize: '14px', width: '100%' }}
                      >
                        {item.para}
                      </Typography>
                    </div>
                  </Box>
                );
              })}
            </Box>
            {screenSize > 899 ? (
              <Box
                sx={{
                  width: '96%',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  marginTop: '2rem',
                  marginBottom: '2rem',
                }}
              >
                <div>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: 'max-content',
                      padding: '10px 15px',
                      backgroundColor: 'var(--light-primary-color)',
                      borderRadius: '8px',
                      gap: '0.5rem',
                    }}
                  >
                    <NetNetCheckBox
                      checked={!showWelcome}
                      onChange={toggleShowWelcome}
                    />
                    <p
                      style={{
                        fontSize: '15px',
                        color: '#000',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      Do not show me the Welcome Screen again
                    </p>
                  </Box>
                  <p
                    style={{
                      marginTop: '0.5rem',
                      fontSize: '1rem',
                    }}
                  >
                    * We will remember it on this device only.
                  </p>
                  <p
                    style={{
                      margin: '0.5rem 0',
                      fontSize: '1rem',
                      color: 'var(--accent-blue)',
                      fontWeight: '700',
                    }}
                  >
                    Once disabled, you will need to go to&nbsp;
                    <Link
                      to={appRoutes.myProfile}
                      style={{
                        textDecoration: 'underline',
                        color: 'var(--primary-color)',
                      }}
                    >
                      My Profile
                    </Link>{' '}
                    to enable it again.
                  </p>
                </div>
              </Box>
            ) : (
              ''
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={7.5}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              borderRadius: '8px',
              alignItems: 'start',
              pt: { xs: 2, sm: 4 },
              pl: { xs: 1, sm: 1, md: 2, lg: 6 },
              pr: { xs: 1, sm: 1, md: 1, lg: 3 },

              gap: { xs: 4, md: 4 },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                width: '100%',
                overflowX: 'hidden',
                backgroundColor: '#fff',
                borderRadius: '12px',
                padding: '10px 10px 0px 15px',
              }}
            >
              <Box
                sx={{ display: 'flex', width: '100%', marginBottom: '10px' }}
              >
                <p
                  style={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                    color: '#03071E',
                  }}
                >
                  Dive Into Net Net
                </p>
              </Box>
              {diveVideos.map((diveItem) => {
                return (
                  <Box
                    key={diveItem?.title}
                    sx={{
                      display: 'flex',
                      position: 'relative',
                      width: '100%',
                      marginBottom: '10px',
                      overflow: 'hidden',
                      height: '90px',
                    }}
                  >
                    <div style={{ width: '125px', height: '80px' }}>
                      <img
                        alt="img"
                        src={diveItem.img}
                        frameBorder="8"
                        style={{
                          borderRadius: '8px',
                          width: '125px',
                          height: '80px',
                        }}
                      />
                      <img
                        src={Vector}
                        style={{
                          position: 'absolute',
                          top: 25,
                          left: 50,
                          width: '30px',
                          height: '30px',
                        }}
                        alt="vector"
                      />
                    </div>
                    <Box
                      sx={{
                        background: 'none',
                        position: 'absolute',
                        width: '95%',
                        height: '120px',
                        left: '0',
                        top: '0',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setShowHelp(true);
                        setClickData(diveItem);
                      }}
                    />
                    <div style={{ width: '100%', padding: '4px 0px 4px 15px' }}>
                      <Typography
                        component="div"
                        variant="h5"
                        sx={{
                          fontSize: '14px',
                          fontWeight: 600,
                          color: '#000000',
                        }}
                      >
                        {diveItem.title}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        color="#000000"
                        component="div"
                        sx={{
                          fontSize: '14px',
                          width: { sm: '100%', xl: '90%' },
                        }}
                      >
                        {diveItem.para}
                      </Typography>
                    </div>
                  </Box>
                );
              })}
            </Box>
          </Grid>
          {screenSize >= 600 && screenSize < 900 && (
            <Box
              sx={{
                width: '96%',
                display: 'flex',
                justifyContent: 'flex-start',
                marginTop: '2rem',
                marginBottom: '2rem',
                pl: 2,
              }}
            >
              <div>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 'max-content',
                    padding: '10px 15px',
                    backgroundColor: 'var(--light-primary-color)',
                    borderRadius: '8px',
                    gap: '0.5rem',
                  }}
                >
                  <NetNetCheckBox
                    checked={!showWelcome}
                    onChange={toggleShowWelcome}
                  />
                  <p
                    style={{
                      fontSize: '15px',
                      color: '#000',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    Do not Show me Welcome Screen again
                  </p>
                </Box>
                <p
                  style={{
                    marginTop: '0.5rem',
                    fontSize: '1rem',
                  }}
                >
                  * We will remember it on this device only.
                </p>
                <p
                  style={{
                    margin: '0.5rem 0',
                    fontSize: '1rem',
                    color: 'var(--accent-blue)',
                    fontWeight: '700',
                  }}
                >
                  Once disabled, you will need to go to&nbsp;
                  <Link
                    to={appRoutes.myProfile}
                    style={{
                      textDecoration: 'underline',
                      color: 'var(--primary-color)',
                    }}
                  >
                    My Profile
                  </Link>{' '}
                  to enable it again.
                </p>
              </div>
            </Box>
          )}
        </Grid>
        <WelComeHelpVideo show={show} setShow={setShow} />
        <WelcomeNewHelpVideo
          showHelp={showHelp}
          setShowHelp={setShowHelp}
          screenSize={screenSize}
          clickData={clickData}
          setClickData={setClickData}
          allVideos={allVideos}
        />
      </section>
    </Bounce>
  ) : (
    <div
      style={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '2rem',
          border: '2px solid var(--light-primary-color)',
          borderRadius: '0.5rem',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 'max-content',
            padding: '10px 15px',
            backgroundColor: 'var(--light-primary-color)',
            borderRadius: '8px',
            gap: '0.5rem',
          }}
        >
          <NetNetCheckBox checked={showWelcome} onChange={toggleShowWelcome} />
          <p
            style={{
              fontSize: '15px',
              color: '#000',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            Show me the Welcome Screen again
          </p>
        </div>
        <p
          style={{
            marginTop: '0.5rem',
            fontSize: '1rem',
          }}
        >
          * We will remember it on this device only.
        </p>
        <p
          style={{
            marginTop: '0.5rem',
            fontSize: '1rem',
            color: 'var(--accent-blue)',
            fontWeight: '700',
            maxWidth: '450px',
            textAlign: 'center',
          }}
        >
          Now we will redirect you to your tasks in {countDown} seconds.
          Remember you can always go to&nbsp;
          <Link
            to={appRoutes.myProfile}
            style={{
              textDecoration: 'underline',
              color: 'var(--primary-color)',
            }}
          >
            My Profile
          </Link>
          &nbsp;to enable it again.
        </p>
      </div>
    </div>
  );
}
