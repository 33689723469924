import { createAsyncThunk } from '@reduxjs/toolkit';
import { ENDPOINT } from '../Opportunity/Redux/oppor';
const GET_MESSAGES = 'netnet/Redux/GET_MESSAGES';
const GET_TEAM = 'netnet/Redux/GET_TEAM';
const GET_ALL_MSG = 'netnet/Redux/GET_ALL_MSG';
const GET_GENERAL = 'netnet/Redux/GET_GENERAL';
const GET_PROJECT_CHAT = 'netnet/Redux/GET_PROJECT_CHAT';
const ADD_FAV = 'netnet/Redux/ADD_FAV';
const GET_FAV = 'netnet/Redux/GET_FAV';
const GET_ESTIMATE_CHANNELS = 'netnet/Redux/GET_ESTIMATE_CHANNELS'
const GET_NEW_CHANNELS = 'netnet/Redux/GET_NEW_CHANNELS'

const initialState = {
  data: null,
  error: null,
  loading: false,
  team: null,
  allMsg: null,
  general: null,
  proChat: null,
  estimate_channels: null,
  new_channels:null,
  fav: [],
};

export const AddFav = (payload) => {
  return {
    type: ADD_FAV,
    payload: payload,
  };
};

export const GetFav = (payload) => {
  return {
    type: GET_FAV,
    payload,
  };
};

export const getMessages = createAsyncThunk(GET_MESSAGES, async (meta) => {
  const response = await fetch(` ${ENDPOINT}/api/chat/channel/list/`, {
    method: 'GET',
    headers: {
      Authorization: `JWT ${meta.access_token}`,
    },
  });
  const data = await response.json();
  return data;
});

export const getTeam = createAsyncThunk(GET_TEAM, async (meta) => {
  const response = await fetch(`${ENDPOINT}/api/user/team/`, {
    method: 'GET',
    headers: {
      Authorization: `JWT ${meta.access_token}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
});

export const getAllMsg = createAsyncThunk(GET_ALL_MSG, async (meta) => {
  const response = await fetch(`${ENDPOINT}/api/chat/dm/message/list/`, {
    method: 'GET',
    headers: {
      Authorization: `JWT ${meta.access_token}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
});
export const generalChat = createAsyncThunk(GET_GENERAL, async (meta) => {
  const response = await fetch(`${ENDPOINT}/api/chat/general/channel/list/`, {
    method: 'GET',
    headers: {
      Authorization: `JWT ${meta.access_token}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
});

export const projectChat = createAsyncThunk(GET_PROJECT_CHAT, async (meta) => {
  const response = await fetch(`${ENDPOINT}/api/project/list/`, {
    method: 'GET',
    headers: {
      Authorization: `JWT ${meta.access_token}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
});

export const estimate_channels = createAsyncThunk(GET_ESTIMATE_CHANNELS, async (meta) => {
  const response = await fetch(`${ENDPOINT}/api/chat/channel/list/`, {
    method: 'GET',
    headers: {
      Authorization: `JWT ${meta.access_token}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  console.error(data, "DataInProject")

  return data;
});

export const New_channels = createAsyncThunk(GET_NEW_CHANNELS, async (meta) => {
  const response = await fetch(`${ENDPOINT}/api/chat/channel/list/new`, {
    method: 'GET',
    headers: {
      Authorization: `JWT ${meta.access_token}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
});

const messagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_MESSAGES}/pending`:
      return {
        ...state,
        loading: true,
      };
    case `${GET_MESSAGES}/fulfilled`:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case `${GET_MESSAGES}/rejected`:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };
    case `${GET_TEAM}/pending`:
      return {
        ...state,
        loading: true,
      };
    case `${GET_TEAM}/fulfilled`:
      return {
        ...state,
        loading: false,
        team: action.payload,
      };
    case `${GET_TEAM}/rejected`:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };
    case `${GET_ALL_MSG}/pending`:
      return {
        ...state,
        loading: true,
      };
    case `${GET_ALL_MSG}/fulfilled`:
      return {
        ...state,
        loading: false,
        allMsg: action.payload,
      };
    case `${GET_ALL_MSG}/rejected`:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };

    case `${GET_GENERAL}/pending`:
      return {
        ...state,
        loading: true,
      };
    case `${GET_GENERAL}/fulfilled`:
      return {
        ...state,
        loading: false,
        general: action.payload,
      };
    case `${GET_GENERAL}/rejected`:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };

    case `${GET_PROJECT_CHAT}/pending`:
      return {
        ...state,
        loading: true,
      };
    case `${GET_PROJECT_CHAT}/fulfilled`:
      return {
        ...state,
        loading: false,
        proChat: action.payload,
      };
    case `${GET_PROJECT_CHAT}/rejected`:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };

    case `${GET_ESTIMATE_CHANNELS}/fulfilled`:
      return {
        ...state,
        loading: false,
        estimate_channels: action.payload,
      };

    case `${GET_ESTIMATE_CHANNELS}/rejected`:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };

    case `${GET_NEW_CHANNELS}/rejected`:
      return {
        ...state,
        loading: false,
        error:action.error.message
      }

    case `${GET_NEW_CHANNELS}/fulfilled`:
      return {
        ...state,
        loading:false,
        new_channels: action.payload
      }
    case ADD_FAV:
      return {
        ...state,
        fav: action.payload,
      };

    case GET_FAV:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default messagesReducer;
