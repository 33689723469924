import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { TextField,Typography, Button, Box, Skeleton } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import StarIcon from '@mui/icons-material/Star';
import { ENDPOINT,} from '../../../Opportunity/Redux/oppor';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { getTeam } from '../../../Stream/streamRedux';
import { PreLoader1 } from '../../../../Componenets/Misc';
import Bounce from 'react-reveal/Bounce';

const YourPlan = ({ funcAlert, apiFunc, team_plan }) => {

    const dispatch = useDispatch()  
  
    const [loading, setLoading] = useState(false)
    const [isBadRequest, setIsBadRequest] = useState(false)
    const [resAlert, setResAlert] = useState()
    const [monthlyLoadingIcon, setMonthlyLoadingIcon] = useState(false)
    const [annualLoadingIcon, setAnnualLoadingIcon] = useState(false)
  
    const [show000, setShow000] = useState(true)
  
  
    const [selectedProjects, setSelectedProjects] = useState("");
    const [price, setPrice] = useState(0);
    const [savings, setSavings] = useState(0);
  
    const [projectsValue, setProjectsValue] = useState(null)
    const handleProjectChange = (projects) => {
      const projectsValue = parseInt(projects);
      setProjectsValue(projectsValue)
  
      setSelectedProjects(projectsValue); // Update selectedProjects immediately
  
      // Calculate price and savings based on the selected number of projects
      if (projectsValue >= 5 && projectsValue <= 15) {
  
        setPrice((projectsValue * 30 * 12) * 85 / 100);
        setSavings((projectsValue * 30 * 12) * 15 / 100);
      } else if (projectsValue >= 16 && projectsValue <= 34) {
        setPrice((projectsValue * 30 * 12) * 75 / 100);
        setSavings((projectsValue * 30 * 12) * 25 / 100);
      } else if (projectsValue >= 35 && projectsValue <= 50) {
        setPrice((projectsValue * 30 * 12) * 65 / 100);
        setSavings((projectsValue * 30 * 12) * 35 / 100);
      } else if (projectsValue >= 51) {
        setPrice((projectsValue * 30 * 12) * 50 / 100);
        setSavings((projectsValue * 30 * 12) * 50 / 100);
      } else {
        // Handle other cases or set a default value
        setPrice(0);
        setSavings(0);
      }
    };
  
    const [planNm, setPlanNm] = useState('')
    const [subscriptExpiry, setSupcriptExpiry] = useState(null)
  
  
    useEffect(() => {
      let api = async () => {
        setLoading(true)
        try {
          const res = await fetch(`${ENDPOINT}/api/superadmin/packages-list/`, {
            method: "GET",
            headers: {
              Authorization: `JWT ${access_token}`,
              "Content-Type": "application/json",
            },
            // body: JSON.stringify(updatedData),
          });
  
          if (res.ok) {
          
            const data = await res.json(); // Parse the response data
  
            let plan = data.filter((v) => v.id === team_plan.team_plan)
  
            if (plan[0].discount_code) {
              let discount_code = plan[0].discount_code
              try {
                const res = await fetch(`${ENDPOINT}/api/superadmin/discount/list/`, {
                  method: "GET",
                  headers: {
                    Authorization: `JWT ${access_token}`,
                    "Content-Type": "application/json",
                  },
                  // body: JSON.stringify(updatedData),
                });
  
                const data1 = await res.json(); // Parse the response data
                if (res.ok) {
                  setLoading(false)
                  const matchingObject = data1.find(obj => {
                    if (obj.code !== null && obj.code !== undefined) {
                      if (Array.isArray(obj.code)) {
                        return obj.code.some(code => code && code.toLowerCase() === discount_code.toLowerCase());
                      } else if (typeof obj.code === 'string') {
                        if (!obj.code.includes('[')) {
                          return obj.code.toLowerCase() === discount_code.toLowerCase();
                        }
                        try {
                          // Replace single quotes with double quotes before parsing
                          const arrayFromJson = JSON.parse(obj.code.replace(/'/g, '"'));
                          return arrayFromJson.some(code => code && code.toLowerCase() === discount_code.toLowerCase());
                        } catch (error) {
                          console.error('Error parsing JSON:', error);
                          return false;
                        }
                      } else {
                        return false;
                      }
                    }
  
                    return false;
                  });
  
                  if (matchingObject) {
  
                    setLoading(false)
                    // Parse the date string into a Date object
                    const targetDate = matchingObject.expiration ? new Date(matchingObject.expiration) : null;
  
                    // Get the current date
                    const currentDate = new Date();
  
                    // Calculate the difference in months
                    const monthsRemaining = targetDate ?
                      (targetDate.getFullYear() - currentDate.getFullYear()) * 12 +
                      (targetDate.getMonth() - currentDate.getMonth())
                      : null;  
                    setValidateMonthlyPkg({
                      amount: matchingObject.amount,
                      amountType: matchingObject.amount_type,
                      discountProject: matchingObject.discounted_projects,
                      expiration: monthsRemaining,
                      code: matchingObject.code
                    })
                  }
                } else {
                  setLoading(false)
                  for (let i in data1) {
                    let msg = data1[i]
                    funcAlert(msg, false)
                  }
                }
              } catch (error) {
                setLoading(false)
                for (let i in data) {
                  let msg = data[i]
                  funcAlert(msg, false)
                }
              }
            }
            if (plan[0]?.name.includes('Annual')) {
              setNextWithOutCoupon(!this)
              setThisPlaneAgainBtn(!this)
              setPlanNm('annual')
              setSelectedProjects(plan[0]?.allowed_projects)
            }
            if (plan[0]?.name.includes('Monthly')) {
              handleCancelAnnual()
              setMonthlyPlane(!this)
              setPlanNm('monthly')
              if (plan[0].price === "28.50") {
                setCouponSwitchScreen(true)
                setCouponSwitchScreenFinal(true)
              }
            }
            setLoading(false)
            try {
  
              const dateApi = await fetch(`${ENDPOINT}/api/sub/detail/${team_plan.team_subscription}/`, {
                method: "GET",
                headers: {
                  Authorization: `JWT ${access_token}`,
                  "Content-Type": "application/json",
                }
              }
              )
              let dataDate = await dateApi.json()
              if (dateApi.ok) {
                const subscriptionExpiry = dataDate?.subscription_expiry;
                const formattedDate = new Date(subscriptionExpiry).toLocaleDateString("en-US", {
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                });
                setSupcriptExpiry(formattedDate)
              }
              else {
              }
            }
            catch (error) {
            }
  
  
          } else {
            setLoading(false)
  
          }
        } catch (error) {
          console.error("An error occurred:", error);
          setLoading(false)
  
        }
  
      }
      api()
    }, [team_plan])
  
    const access_token = useSelector((state) => state.auth.user.access);
  
    const handleClick = async (planName) => {
  
      let updatedData = {};
  
      if (planName === "monthly") {
        setMonthlyLoadingIcon(true)
        if (validate) {
          updatedData = {
            plan_name: "Monthly-Final",
            plan_price: null,
            number_of_projects: null,
            discount_code: validateValue
          };
        }
        else {
          updatedData = {
            plan_name: "Monthly-Final",
            plan_price: null,
            number_of_projects: null,
          };
        }
      } else if (planName === "annual") {
        setAnnualLoadingIcon(true)
        if (validateAnnual) {
          updatedData = {
            plan_name: "Annual-Final",
            plan_price: price,
            number_of_projects: selectedProjects,
            discount_on_annual_plan: savings,
            discount_code: validateValueAnnual
          };
        }
        else {
          updatedData = {
            plan_name: "Annual-Final",
            plan_price: price,
            number_of_projects: selectedProjects,
            discount_on_annual_plan: savings,
          };
        }
      }
  
      else if (planName === 'free') {
        updatedData = {
          plan_name: "free",
          plan_price: null,
          number_of_projects: null,
        };
      }
  
      try {
        const res = await fetch(`${ENDPOINT}/api/sub/update-plan/`, {
          method: "PUT",
          headers: {
            Authorization: `JWT ${access_token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedData),
        });
  
        const data = await res.json();
  
        setMonthlyLoadingIcon(false)
        setAnnualLoadingIcon(false)
  
        if (res.ok) {
  
          if (validate) {
  
          } else if (validateAnnual) {
  
          }
          else {
            setValidateMonthlyPkg({
              ...validateMonthlyPkg,
              code: ''
            })
          }
  
          if (data?.Error) {
            for (let i in data) {
              let msg = data[i]
              funcAlert(msg, false)
            }
          }
          else {
  
            setCouponSwitchScreenFinal(true)
            apiFunc()         
            setPlanNm(planName)
  
            if (planName === 'annual') {
              setThisPlaneAgainBtn(!this)
            }
            if (planName === 'monthly') {
              handleCancelAnnual()
            }
            if (planName === 'free') {
              handleCancelAnnual()
            }
  
            dispatch(getTeam({ access_token }))
  
            try {
  
              const dateApi = await fetch(`${ENDPOINT}/api/sub/detail/${team_plan.team_subscription}/`, {
                method: "GET",
                headers: {
                  Authorization: `JWT ${access_token}`,
                  "Content-Type": "application/json",
                }
              }
              )
              let dataDate = await dateApi.json()
              if (dateApi.ok) {
                const subscriptionExpiry = dataDate?.subscription_expiry;
                const formattedDate = new Date(subscriptionExpiry).toLocaleDateString("en-US", {
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                });
                setSupcriptExpiry(formattedDate)
              }
              else {
              }
            }
            catch (error) {
              console.error('failed to Expiry Date', error)
            }
          }
  
        } else {
          for (let i in data) {
            let msg = data[i]
            funcAlert(msg, false)
          }   
        }
      } catch (error) {
        setMonthlyLoadingIcon(false)
        setAnnualLoadingIcon(false)
        funcAlert('Something went wrong, please contact Net Net support staff to resolve this issue.', false)
      }
    };
  
    const [isParagraphVisible, setIsParagraphVisible] = useState(false);
  
    const toggleParagraph = () => {
      setIsParagraphVisible(!isParagraphVisible);
    };
  
    // this is new component
    const [monthlyPlane, setMonthlyPlane] = useState(false)
    const handlemonthlyBtn = (planName) => {
  
      if (monthlyPlane === true) {
        if (validate === true) {
        }
        else {
          handleClick(planName)
        }
      }
      else {
        setMonthlyPlane(!monthlyPlane)
        setValidate(false)
      }
    }
  
    const [couponSwitchScreen, setCouponSwitchScreen] = useState(false)
    const [couponSwitchScreenFinal, setCouponSwitchScreenFinal] = useState(false)
    const [typeSwitchMonthly, setTypeSwitchMonthly] = useState('')
    const handleCouponSwitchPlan = () => {
      setCouponSwitchScreen(true)
      if (couponSwitchScreen) {
        handleClick('monthly')
      }
    }
  
    const [validateMonthlyPkg, setValidateMonthlyPkg] = useState({
      amount: '',
      amountType: '',
      discountProject: '',
      expiration: '',
      code: '',
    })
  
  
    const [validate, setValidate] = useState(false)
    const handleValidate = async () => {
      try {
        const res = await fetch(`${ENDPOINT}/api/sub/discount/validate/${validateValue}/`, {
          method: "GET",
          headers: {
            Authorization: `JWT ${access_token}`,
            "Content-Type": "application/json",
          },
          // body: JSON.stringify(updatedData),
        });
  
        const data = await res.json(); // Parse the response data
        if (res.ok) {
  
          try {
            const res = await fetch(`${ENDPOINT}/api/superadmin/discount/list/`, {
              method: "GET",
              headers: {
                Authorization: `JWT ${access_token}`,
                "Content-Type": "application/json",
              },
              // body: JSON.stringify(updatedData),
            });
  
            const data1 = await res.json(); // Parse the response data
            if (res.ok) {
              const matchingObject = data1.find(obj => {
                if (obj.code !== null && obj.code !== undefined) {
                  if (Array.isArray(obj.code)) {
                    return obj.code.some(code => code && code.toLowerCase() === validateValue.toLowerCase());
                  } else if (typeof obj.code === 'string') {
                    if (!obj.code.includes('[')) {
                      return obj.code.toLowerCase() === validateValue.toLowerCase();
                    }
                    try {
                      // Replace single quotes with double quotes before parsing
                      const arrayFromJson = JSON.parse(obj.code.replace(/'/g, '"'));
                      return arrayFromJson.some(code => code && code.toLowerCase() === validateValue.toLowerCase());
                    } catch (error) {
                      console.error('Error parsing JSON:', error);
                      return false;
                    }
                  } else {
                    return false;
                  }
                }
  
                return false;
              });
  
              if (matchingObject) {
  
                // Parse the date string into a Date object
                const targetDate = matchingObject.expiration ? new Date(matchingObject.expiration) : null;
  
                // Get the current date
                const currentDate = new Date();
  
                // Calculate the difference in months
                const monthsRemaining = targetDate ?
                  (targetDate.getFullYear() - currentDate.getFullYear()) * 12 +
                  (targetDate.getMonth() - currentDate.getMonth())
                  : null;
  
  
                setValidate(true)
                setValidateMonthlyPkg({
                  amount: matchingObject.amount,
                  amountType: matchingObject.amount_type,
                  discountProject: matchingObject.discounted_projects,
                  expiration: monthsRemaining,
                })
              }
  
  
            } else {
              for (let i in data1) {
                let msg = data1[i]
                funcAlert(msg, false)
              }
            }
          } catch (error) {
            console.error("An error occurred:", error);
            for (let i in data) {
              let msg = data[i]
              funcAlert(msg, false)
            }
          }
  
          if (data?.Error) {
            for (let i in data) {
              let msg = data[i]
              funcAlert(msg, false)
            }
          }
        } else {
          setLoading(false)
  
          for (let i in data) {
            let msg = data[i]
            funcAlert(msg, false)
          }
        }
      } catch (error) {
        setLoading(false)
        for (let i in error) {
          let msg = error[i]
          funcAlert(msg, false)
        }
      }
    }
  
    // this is annual component
  
    const [next, setNext] = useState(false)
    const [nextWithOutCoupon, setNextWithOutCoupon] = useState(false)
    const handleAnnualBtn = () => {
      if (next) {
        setNextWithOutCoupon(!nextWithOutCoupon)
      }
      else if (!next) {
        setNext(!next)
        setValidateAnnual(false)
      }
    }
  
    const [validateAnnual, setValidateAnnual] = useState(false)
    const handleValidateAnnual = async () => {  
      try {
        const res = await fetch(`${ENDPOINT}/api/sub/discount/validate/${validateValueAnnual}/`, {
          method: "GET",
          headers: {
            Authorization: `JWT ${access_token}`,
            "Content-Type": "application/json",
          },
          // body: JSON.stringify(updatedData),
        });
  
        const data = await res.json(); // Parse the response data
        if (res.ok) {  
          try {
            const res = await fetch(`${ENDPOINT}/api/superadmin/discount/list/`, {
              method: "GET",
              headers: {
                Authorization: `JWT ${access_token}`,
                "Content-Type": "application/json",
              },
              // body: JSON.stringify(updatedData),
            });
  
            const data1 = await res.json(); // Parse the response data
            if (res.ok) {
              const matchingObject = data1.find(obj => {
                if (obj.code !== null && obj.code !== undefined) {
                  if (Array.isArray(obj.code)) {
                    return obj.code.some(code => code && code.toLowerCase() === validateValueAnnual.toLowerCase());
                  } else if (typeof obj.code === 'string') {
                    if (!obj.code.includes('[')) {
                      return obj.code.toLowerCase() === validateValueAnnual.toLowerCase();
                    }
                    try {
                      // Replace single quotes with double quotes before parsing
                      const arrayFromJson = JSON.parse(obj.code.replace(/'/g, '"'));
                      return arrayFromJson.some(code => code && code.toLowerCase() === validateValueAnnual.toLowerCase());
                    } catch (error) {
                      return false;
                    }
                  } else {
                    return false;
                  }
                }
  
                return false;
              });
  
              if (matchingObject) {
  
                // Parse the date string into a Date object
                const targetDate = matchingObject.expiration ? new Date(matchingObject.expiration) : null;
  
                // Get the current date
                const currentDate = new Date();
  
                // Calculate the difference in months
                const monthsRemaining = targetDate ?
                  (targetDate.getFullYear() - currentDate.getFullYear()) * 12 +
                  (targetDate.getMonth() - currentDate.getMonth())
                  : null;  
                setValidateAnnual(true)
                setValidateMonthlyPkg({
                  amount: matchingObject.amount,
                  amountType: matchingObject.amount_type,
                  discountProject: matchingObject.discounted_projects,
                  expiration: monthsRemaining,
                })
              }
  
  
            } else {
              for (let i in data1) {
                let msg = data1[i]
                funcAlert(msg, false)
              }
            }
          } catch (error) {
            console.error("An error occurred:", error);
            for (let i in data) {
              let msg = data[i]
              funcAlert(msg, false)
            }
          }
  
          if (data?.Error) {
            for (let i in data) {
              let msg = data[i]
              funcAlert(msg, false)
            }
          }
        } else {
          setLoading(false)
          for (let i in data) {
            let msg = data[i]
            funcAlert(msg, false)
          }
  
        }
      } catch (error) {
        setLoading(false)
        for (let i in error) {
          let msg = error[i]
          funcAlert(msg, false)
        }
      }
    }
  
    const handleCancelAnnual = () => {
      setNext(false)
      setNextWithOutCoupon(false)
      setThisPlaneBtn(false)
      setThisPlaneAgainBtn(false)
    }
  
    const [thisPlaneBtn, setThisPlaneBtn] = useState(false)
    const [thisPlaneAgainBtn, setThisPlaneAgainBtn] = useState(false)
    const handleSwitchToThisPlaneBtn = (planName) => {
      if (!thisPlaneBtn) {
        setThisPlaneBtn(!thisPlaneBtn)
      }
      else if (typeSwitch === 'switch') {
        handleClick(planName)
      }
    }
  
    const [typeSwitch, setTypeSwitch] = useState('')
    const changeTypeSwitch = (e) => {
      setTypeSwitch(e.target.value)
    }
  
    const [validateValue, setValidateValue] = useState('')
    const [validateValueAnnual, setValidateValueAnnual] = useState('')
  
  
    return (
      <Bounce bottom>
        <>
          {isBadRequest && (
            <Box
              sx={{
                width: '850px',
                height: '300px',
                padding: '2rem',
                borderRadius: '8px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
                bottom: '40%',
                left: { xl: '23%', lg: '5%', md: '5%' },
                zIndex: 999
              }}
            >
              <Box
                sx={{
                  width: '98%',
                  height: '95%',
                  backgroundColor: 'rgba(255,255,255)',
                  border: '1px solid #d9d9d9',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  borderRadius: '8px',
                  padding: '0.68rem 1rem',
                  justifyContent: 'space-around',
                  boxShadow: '2px 2px 6px 0px #D9D9D9',
                }}
              >
                <p
                  style={{
                    fontSize: '1.1rem',
                    fontWeight: '500',
                    fontFamily: 'Open Sans, sans-serif',
                    color: '#db8000',
                  }}
                >
                  <InfoOutlinedIcon SX={{ color: '#db8000', fontSize: '22px', marginRight: '10px' }} /> Update Your Information
                </p>
                <p style={{
                  color: '#232125',
                  letterSpacing: '-0.3px',
                  textAlign: 'center',
                  fontSize: '1rem',
                  fontFamily: '\'Open sans\', sans-serif',
                  fontWeight: '500'
                }}>
                  {resAlert}
                </p>
  
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '50%',
                    margin: '0 auto'
                  }}
                >
                </Box>
              </Box>
            </Box>
          )}
          {
            loading ?
  
              <section className="tw-text-gray-600 tw-body-font overflow-hidden" style={{ fontFamily: "Open Sans" }}>
                <Box sx={{ width: '90%', margin: '0 auto', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Skeleton variant="rectangular" width={'32%'} height={600} sx={{ borderRadius: '8px' }} />
                  <Skeleton variant="rectangular" width={'32%'} height={600} sx={{ borderRadius: '8px' }} />
                  <Skeleton variant="rectangular" width={'32%'} height={600} sx={{ borderRadius: '8px' }} />
                </Box>
              </section>
              :
              <section className="tw-text-gray-600 tw-body-font overflow-hidden" style={{ fontFamily: "Open Sans" }}>
                <div className="px-2 pt-2 pb-5">
                  <div className="d-flex flex-wrap -m-4" style={{ justifyContent: 'space-evenly' }}>
                    <div style={{ width: "33%", maxWidth: '430px' }} className="p-3  md:tw-w-1/3 tw-w-full">
                      <Box sx={{ border: planNm !== 'monthly' && planNm !== 'annual' ? '2px solid #8138ff' : '1px solid #D9D9D9', borderRadius: '15px !important', fontSize: { sm: '15px', md: '16px' } }} className="h-100 p-4 tw-bg-[#f7f7fa] rounded d-flex flex-column position-relative overflow-hidden">
                        <h5 className="text-sm tracking-widest tw-title-font mt-4 mb-1 font-medium" style={{ color: "#03071E66" }}>
                          Net Net
                        </h5>
                        <h3 className="tw-text-2xl font-bold text-black pb-1 mb-2  tw-border-gray-200 tw-leading-none" style={{ marginTop: '-10px' }}>
                          Free
                        </h3>
                        <p className="tw-text-3xl tw-font-bold tw-text-gray-900 tw-leading-none tw-flex tw-items-center tw-pb-4 tw-mb-4 tw-border-gray-200">
                          <span className='text-black' style={{ fontSize: "26px", fontWeight: 900 }}>$0</span>
                          <span className="tw-text-lg ms-1 tw-font-normal tw-text-gray-500" style={{ color: "#03071E66", fontWeight: 500 }}>/ Month</span>
                        </p>
                        <p className="tw-text-[#372580] tw-text-base">
                          <p className="d-flex align-items-center py-1 tw-text-gray-600 ">
                            <span className="me-2 d-inline-flex align-items-center justify-content-center">
                              <CheckCircleIcon sx={{ color: "#711fff" }} />
                            </span>
                            1 Job Free For Life
                          </p>
                          <p className="d-flex align-items-center py-1 tw-text-gray-600">
                            <span className="me-2 tw-inline-flex tw-items-center tw-justify-center">
                              <CheckCircleIcon sx={{ color: "#711fff" }} />
                            </span>
                            Some Limitations Apply
                          </p>
                        </p>
                        {
                          planNm !== 'monthly' && planNm !== 'annual' ?
                            // false ? 
                            <button style={{ background: "white", border: "1px solid #D9D9D9", height: '55px', color: "#D9D9D9" }} className="d-flex align-items-center mt-auto tw-text-gray-400 text-center justify-content-center tw-bg-white tw-border py-2 px-4 w-100 tw-focus:outline-none rounded">
                              Current Plan
                            </button>
                            :
                            <button
                              style={{ background: "#711fff", height: '55px', marginTop: "inherit", marginTop: 'auto' }}
                              onClick={() => {
  
                                // funcAlert('Unable to change the subscription plan to free plan, please contect Net Net staff to change your subscription ', false)
                                handleClick('free')
                              }}
  
                              className="d-flex align-items-center justify-content-center text-center text-white tw-bg-[#711fff] tw-border-0 py-2 px-4 w-100 tw-focus:outline-none rounded">
                              Switch To This Plan
                            </button>
  
                        }
                      </Box>
                    </div>
                    <div style={{ width: "33%", maxWidth: '430px' }} className="p-3  md:tw-w-1/3 tw-w-full">
                      <Box sx={{ border: planNm === 'monthly' ? '2px solid #8138ff' : '1px solid #D9D9D9', borderRadius: '15px !important', fontSize: { sm: '15px', md: '16px' } }} className="h-100 p-4 tw-bg-[#f7f7fa] rounded d-flex flex-column position-relative overflow-hidden">
                        <h5 className="tw-text-sm tracking-widest tw-title-font mt-4 mb-1 font-medium" style={{ color: "#03071E66" }}>
                          Net Net
                        </h5>
                        <h3 className="tw-text-2xl font-bold text-black pb-1 mb-2  tw-border-gray-200 tw-leading-none" style={{ marginTop: '-10px' }}>
                          Monthly
                        </h3>
  
                        {
                          !monthlyPlane ?
  
                            <>
                              <p className="tw-text-3xl tw-font-bold tw-text-gray-900 tw-leading-none tw-flex tw-items-center tw-pb-4 tw-mb-4 tw-border-gray-200">
                                <span className='text-black' style={{ fontSize: "26px", fontWeight: 900 }}>$30</span>
                                <span className="tw-text-lg ms-1 tw-font-normal tw-text-gray-500" style={{ color: "#03071E66", fontWeight: 500 }}>/ Job / Month</span>
                              </p>
                              <p className="tw-text-[#372580] tw-text-base">
                                <p className="d-flex align-items-center py-1 tw-text-gray-600">
                                  <span className="me-2  d-inline-flex align-items-center justify-content-center">
                                    <CheckCircleIcon sx={{ color: "#711fff" }} />
                                  </span>
                                  Best for smaller firms
                                </p>
  
                                <p className="d-flex align-items-center py-1 tw-text-gray-600">
                                  <span className="me-2 d-inline-flex align-items-center justify-content-center">
                                    <CheckCircleIcon sx={{ color: "#711fff" }} />
                                  </span>
                                  No Limitations
                                </p>
                                <p className="d-flex align-items-center py-1 tw-text-gray-600 ">
                                  <span className="me-2  d-inline-flex align-items-center justify-content-center">
                                    <CheckCircleIcon sx={{ color: "#711fff" }} />
                                  </span>
                                  Activate & Deactivate Jobs At Any Time
                                </p>
                                <p className="d-flex align-items-center py-1 tw-text-gray-600 ">
                                  <span className="me-2 d-inline-flex align-items-center justify-content-center">
                                    <CheckCircleIcon sx={{ color: "#711fff" }} />
                                  </span>
                                  Billing is Pro-rated on date of activation or deactivation
                                </p>
                              </p>
                            </>
                            :
                            couponSwitchScreen ?
                              couponSwitchScreenFinal ?
                                <>
                                  <Box align='center' sx={{ backgroundColor: '#5FCEA8', p: 2, borderRadius: 2, mt: 2 }}>
                                    <Typography sx={{ marginBottom: '20px', color: "black" }}>Coupon Code Used, <span style={{ fontWeight: '700' }}>
                                      {
                                        validateMonthlyPkg?.amountType === '%' ?
                                          validateMonthlyPkg?.amount + ' % '
                                          :
                                          validateMonthlyPkg?.amountType === 'amount' ?
                                            "$ " + validateMonthlyPkg?.amount + ' '
                                            :
                                            validateMonthlyPkg?.amount + " jobs "
                                      }
                                      Discount</span> </Typography>
                                    <TextField value={validateValue} readOnly={true} fullWidth id="outlined-basic" variant="outlined" outline='lightgray' placeholder='_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ ' sx={{
                                      '& .MuiOutlinedInput-input': {
                                        textAlign: 'center',
                                        backgroundColor: 'white',
                                        color: "#8C8E8E",
                                        // height: '20px',
                                        outline: 'none !important',
                                        fontSize: "24px",
                                        fontWeight: 600,
                                        borderRadius: '5px',
                                        py: 2.5,
  
                                      },
                                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                      {
                                        borderColor: "lightgray", // Remove outline color on focus
                                        outline: 'none !important'
                                      },
                                    }} />
                                    <Typography sx={{ marginBottom: '20px', color: "black", fontWeight: '700', mt: 0.5 }}>Valid for {validateMonthlyPkg.expiration} months</Typography>
                                  </Box>
                                  <p className="tw-text-[#372580] tw-text-base mt-4">
  
                                    <p className="d-flex align-items-center py-1 tw-text-gray-600">
                                      <span className="me-2 d-inline-flex align-items-center justify-content-center">
                                        <CheckCircleIcon sx={{ color: "#A16BFF" }} />
                                      </span>
                                      No Limitations
                                    </p>
                                    <p className="d-flex align-items-center py-1 tw-text-gray-600 ">
                                      <span className="me-2  d-inline-flex align-items-center justify-content-center">
                                        <CheckCircleIcon sx={{ color: "#A16BFF" }} />
                                      </span>
                                      Activate & Deactivate Jobs At Any Time
                                    </p>
                                    <p className="d-flex align-items-center py-1 tw-text-gray-600 ">
                                      <span className="me-2 d-inline-flex align-items-center justify-content-center">
                                        <CheckCircleIcon sx={{ color: "#A16BFF" }} />
                                      </span>
                                      Billing is Pro-rated on date of activation or deactivation
                                    </p>
                                  </p>
                                  <Box align='center' sx={{ mt: 'auto', mb: 2 }}>
                                    <Typography color='text.secondary' fontWeight={200}>Renews Monthly Until Cancelled</Typography>
                                  </Box>
                                </>
                                :
                                <>
                                  <Box align='center' sx={{ backgroundColor: '#711fff', borderRadius: 2, minHeight: '70px', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                                    <strong style={{ fontSize: '35px', fontWeight: 600, color: 'white' }}>$30 </strong>{" "}
                                    <Typography sx={{ color: 'white', fontSize: '20px' }}> /Project /Month</Typography>
                                  </Box>
                                 
                                  {
                                    validate &&
                                    < Box sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                      {
                                        validateMonthlyPkg.amountType === '%' ?
                                          <Typography sx={{ color: '#00B28F', fontWeight: "bold", mt: 2 }}>{` Discount of ${validateMonthlyPkg.amount}% for ${validateMonthlyPkg.expiration ? validateMonthlyPkg.expiration + '' : 'every'} months will be applied.`}</Typography>
                                          :
                                          ''
                                      }
                                      {
                                        validateMonthlyPkg.amountType === "projects" ?
                                          <Typography sx={{ color: '#00B28F', fontWeight: "bold", mt: 2 }}>{`${validateMonthlyPkg.amount} Jobs will be free for ${validateMonthlyPkg.expiration ? validateMonthlyPkg.expiration + '' : 'every'} months.`}</Typography>
                                          :
                                          ''
                                      }
                                      {
                                        validateMonthlyPkg.amountType === "amount" ?
                                          <Typography sx={{ width: { xs: '100%', md: '85%' }, textAlign: 'center', color: '#00B28F', fontWeight: "bold", mt: 2 }}>{`The amount of $ ${validateMonthlyPkg.amount} will be deducted from your total invoice.`}</Typography>
                                          :
                                          ''
                                      }
                                    </Box>
                                  }
                                  <Typography mt={validate ? 10 : 15} align='center' fontWeight='bolder' sx={{ width: { xs: '100%', md: '100%' } }}>Are You Sure You Want To Switch to this plan</Typography>  
                                  <Box align='center' sx={{ backgroundColor: '#e9e9e9', p: 2, borderRadius: 2, mt: 2, mb: 20 }}>
                                    <Typography sx={{ marginBottom: '20px', mb: 1 }}>Please type switch to conform </Typography>
                                    <TextField fullWidth variant="outlined" outline='lightgray' placeholder='switch'
                                      value={typeSwitchMonthly}
                                      onChange={(e) => setTypeSwitchMonthly(e.target.value)}
                                      sx={{
                                        '& .MuiOutlinedInput-input': {
                                          textAlign: 'center',
                                          backgroundColor: 'white',
                                          height: '20px'
                                        },

                                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                        {
                                          borderColor: "gray", // Remove outline color on focus
                                        },
                                      }} />
                                  </Box>
  
                                  <Box align='center' sx={{ mt: 'auto', mb: 2 }}>
                                    <Typography color='text.secondary' fontWeight={200}>Renews Monthly Until Cancelled</Typography>
                                  </Box>
                                </>
                              :
                              planNm === 'monthly' ?
                                <>
                                  <Box align='center' sx={{ backgroundColor: '#711fff', borderRadius: 2, minHeight: '70px', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                                    <strong style={{ fontSize: '35px', fontWeight: 600, color: 'white' }}>$30 </strong>{" "}
                                    <Typography sx={{ color: 'white', fontSize: '20px' }}> /Job /Month</Typography>
                                  </Box>
                                  {validateMonthlyPkg?.code !== '' && validateMonthlyPkg?.code &&
                                    <Box align='center' sx={{ backgroundColor: '#5FCEA8', p: 2, borderRadius: 2, mt: 2 }}>
                                      <Typography sx={{ marginBottom: '20px', color: "black" }}>Coupon Code Used, <span style={{ fontWeight: '700' }}>
                                        {
                                          validateMonthlyPkg?.amountType === '%' ?
                                            validateMonthlyPkg?.amount + ' % '
                                            :
                                            validateMonthlyPkg?.amountType === 'amount' ?
                                              "$ " + validateMonthlyPkg?.amount + ' '
                                              :
                                              validateMonthlyPkg?.amount + " jobs "
                                        }
                                        Discount</span> </Typography>
                                      <TextField value={validateMonthlyPkg?.code} readOnly={true} fullWidth id="outlined-basic" variant="outlined" outline='lightgray' placeholder='_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ ' sx={{
                                        '& .MuiOutlinedInput-input': {
                                          textAlign: 'center',
                                          backgroundColor: 'white',
                                          color: "#8C8E8E",
                                          // height: '20px',
                                          outline: 'none !important',
                                          fontSize: "24px",
                                          fontWeight: 600,
                                          borderRadius: '5px',
                                          py: 2.5,
  
                                        },
                                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                        {
                                          borderColor: "lightgray", // Remove outline color on focus
                                          outline: 'none !important'
                                        },
                                      }} />
                                      <Typography sx={{ marginBottom: '20px', color: "black", fontWeight: '700', mt: 0.5 }}>Valid for {validateMonthlyPkg.expiration} months</Typography>
                                    </Box>
                                  }
  
                                  <p className="tw-text-[#372580] tw-text-base mt-4">
                                    <p className="d-flex align-items-center py-1 tw-text-gray-600">
                                      <span className="me-2 d-inline-flex align-items-center justify-content-center">
                                        <CheckCircleIcon sx={{ color: "#711fff" }} />
                                      </span>
                                      No Limitations
                                    </p>
                                    <p className="d-flex align-items-center py-1 tw-text-gray-600 ">
                                      <span className="me-2  d-inline-flex align-items-center justify-content-center">
                                        <CheckCircleIcon sx={{ color: "#711fff" }} />
                                      </span>
                                      Activate & Deactivate Jobs At Any Time
                                    </p>
                                    <p className="d-flex align-items-center py-1 tw-text-gray-600 ">
                                      <span className="me-2 d-inline-flex align-items-center justify-content-center">
                                        <CheckCircleIcon sx={{ color: "#711fff" }} />
                                      </span>
                                      Billing is Pro-rated on date of activation or deactivation
                                    </p>
                                  </p>
  
  
                                  <Box align='center' sx={{ mt: 'auto', mb: 2 }}>
                                    <Typography sx={{ fontWeight: 600, fontSize: "14px", color: '#00B28F', textAlign: "center", mb: 2 }}>Plan begins on {subscriptExpiry}</Typography>
                                  </Box>
                                </>
                                :
                                <>
                                  <Box align='center' sx={{ backgroundColor: '#711fff', borderRadius: 2, minHeight: '70px', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                                    <strong style={{ fontSize: '35px', fontWeight: 600, color: 'white' }}>$30 </strong>{" "}
                                    <Typography sx={{ color: 'white', fontSize: '20px' }}> /Project /Month</Typography>
                                  </Box>
                                  <Typography align='center' sx={{ fontWeight: 'bold', my: 4 }}> Do You Have Discount Code ? </Typography>
  
                                  <Box align='center' sx={{ backgroundColor: '#E9ECEF', p: 2, borderRadius: 2, mt: 2 }}>
                                    <Typography sx={{ marginBottom: '20px' }}>Enter Your Discount Code</Typography>
                                    <TextField value={validateValue} onChange={(e) => setValidateValue(e.target.value)} fullWidth id="outlined-basic" variant="outlined" outline='lightgray' placeholder='_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ ' sx={{
                                      '& .MuiOutlinedInput-input': {
                                        textAlign: 'center',
                                        backgroundColor: 'white',
                                        color: "#8C8E8E",
                                        // height: '20px',
                                        outline: 'none !important',
                                        fontSize: "24px",
                                        fontWeight: 600,
                                        borderRadius: '5px',
                                        py: 2.5,
  
                                      },
                                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                      {
                                        borderColor: "lightgray", // Remove outline color on focus
                                        outline: 'none !important'
                                      },
                                    }} />
  
                                  </Box>
  
                                  <Box align='center' my={4} sx={{}}>
                                    {
                                      !validate ?
                                        <Button disabled={!validateValue ? true : false} fullWidth variant="contained" color='success' sx={{ textDecoration: 'none', width: { xs: '100%', md: '70%' } }}
                                          onClick={handleValidate}>Validate</Button>
                                        :
                                        <>
                                          <CheckCircleIcon sx={{ color: "#00B28F", display: "block", my: 2, fontSize: '30px' }} />
                                          <Button variant='contained' sx={{ textDecoration: 'none', width: { xs: '100%', md: '70%' }, backgroundColor: "#5EE690", boxShadow: 'inherit !important', '&:hover': { backgroundColor: 'lightgreen !important', } }}
                                          >Success</Button>
                                          {
                                            validateMonthlyPkg.amountType === '%' ?
                                              <Typography sx={{ color: '#00B28F', fontWeight: "bold", mt: 2 }}>{` Discount of ${validateMonthlyPkg.amount}% for ${validateMonthlyPkg.expiration ? validateMonthlyPkg.expiration + '' : 'every'} months will be applied.`}</Typography>
                                              :
                                              ''
                                          }
                                          {
                                            validateMonthlyPkg.amountType === "projects" ?
                                              <Typography sx={{ color: '#00B28F', fontWeight: "bold", mt: 2 }}>{`${validateMonthlyPkg.amount} Jobs will be free for ${validateMonthlyPkg.expiration ? validateMonthlyPkg.expiration + '' : 'every'} months.`}</Typography>
                                              :
                                              ''
                                          }
                                          {
                                            validateMonthlyPkg.amountType === "amount" ?
                                              <Typography sx={{ color: '#00B28F', fontWeight: "bold", mt: 2 }}>{`The amount of $ ${validateMonthlyPkg.amount} will be deducted from your total invoice.`}</Typography>
                                              :
                                              ''
                                          }
                                        </>
                                    }
  
                                  </Box>
                                  <Box align='center' sx={{ mt: 'auto', mb: 2 }}>
                                    <Typography color='text.secondary' fontWeight={200}>Renews Monthly Until Cancelled</Typography>
                                  </Box>
                                </>
                        }
                        {
                          planNm === 'monthly' ?
  
                            <>
                              <Box align='center' sx={{ backgroundColor: '#F1F3F5', color: '#03071E66', borderRadius: 2, width: '100%', height: "55px", alignItems: 'center', display: "flex", justifyContent: 'center' }}>
                                <Typography>Current Plan</Typography>
                              </Box>
                            </>
                            :
                            validate ?
  
                              <button
                                style={{ background: couponSwitchScreen && typeSwitchMonthly !== 'switch' ? "#A16BFF" : "#711fff", height: '55px', marginTop: "inherit", marginTop: !monthlyPlane ? 'auto' : '' }}
                                // onClick={() => handleClick("Net Net Monthly")}
                                onClick={handleCouponSwitchPlan}
                                disabled={couponSwitchScreen && typeSwitchMonthly !== 'switch'}
  
                                className="d-flex align-items-center justify-content-center text-center text-white tw-bg-[#711fff] tw-border-0 py-2 px-4 w-100 tw-focus:outline-none rounded">
                                {
                                  monthlyLoadingIcon ?
                                    <Box style={{ width: '40px', height: '40px' }}>
                                      <PreLoader1 />
                                    </Box>
                                    :
  
                                    'Switch To This Plan'
                                }
                              </button>
                              :
                              <button
                                style={{ background: monthlyPlane ? "#A16BFF" : "#711fff", height: '55px', marginTop: "inherit", marginTop: !monthlyPlane ? 'auto' : '' }}
                                onClick={() => handlemonthlyBtn('monthly')}  
                                className="d-flex align-items-center justify-content-center text-center text-white tw-bg-[#711fff] tw-border-0 py-2 px-4 w-100 tw-focus:outline-none rounded">
                                {
                                  monthlyLoadingIcon ?
                                    <Box style={{ width: '40px', height: '40px' }}>
                                      <PreLoader1 />
                                    </Box>
                                    :
                                    monthlyPlane ?
                                      'Save With Out Coupon Code'
                                      :
                                      'Switch To This Plan'
                                }
  
                              </button>
                        }
                      </Box>
                    </div>
                    <div style={{ width: "33%", maxWidth: '430px' }} className="p-3  md:tw-w-1/3 tw-w-full">
                      <Box sx={{ border: planNm === 'annual' ? '2px solid #8138ff' : '1px solid #D9D9D9', borderRadius: '15px !important', background: "#f7f7fa", fontSize: { sm: '15px', md: '16px' } }} className="h-100 p-4 tw-bg-[#f7f7fa] rounded d-flex flex-column position-relative overflow-hidden">
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                          <Box>
                            <span style={{ background: "#711FFF", color: "white", padding: "10px 10px", right: 0 }} className="tw-bg-[#711fff] tw-text-white d-flex w-100 py-2 align-items-center justify-content-center tracking-widest tw-text-xs text-center position-absolute right-0 top-0 ">
                              <StarIcon sx={{ color: "orange", fontSize: 18 }} /> DISCOUNTED <StarIcon sx={{ color: "orange", fontSize: 18 }} />
                            </span>
                            <h5 className="tw-text-sm tw-tracking-widest tw-title-font mt-4 tw-font-medium" style={{ color: "#03071E66" }}>
                              Net Net
                            </h5>
                            <h3 className="tw-text-2xl tw-font-bold text-black pb-4 mb-2  tw-border-gray-200 tw-leading-none" style={{ marginTop: '-10px' }}>
                              Annual
                            </h3>
  
                            {
                              !nextWithOutCoupon ?
                                !next ?
                                  <>
                                    <strong className="text-black">
                                      Want big savings? Pre purchasing jobs annually really adds
                                      up
                                    </strong>
                                    <p className="d-flex align-items-center py-1 text-black mt-2 ">
                                      <span
                                        style={{ marginRight: "8px" }}
                                        className={` mr-2 text-gray-500 d-inline-flex align-items-center justify-content-center`}
                                      >
                                        <RemoveCircleIcon style={{ color: 'gray' }} />
                                      </span>
                                      15 to 50 % Off (5-51+ Jobs)
                                    </p>
  
                                    <p className="d-flex align-items-center py-1 text-black ">
                                      <span style={{ marginRight: "8px" }} className="mr-2 d-inline-flex align-items-center justify-content-center">
                                        <CheckCircleIcon sx={{ color: "#711fff" }} />
                                      </span>
                                      No Limitations
                                    </p>
                                    <p className="d-flex align-items-center py-1 text-black ">
                                      <span style={{ marginRight: "8px" }} className="mr-2  d-inline-flex align-items-center justify-content-center">
                                        <CheckCircleIcon sx={{ color: "#711fff" }} />
                                      </span>
                                      Additional Job billed at standard monthly rates
                                    </p>
                                    <div className="justify-content-center align-items-center text-center space-y-2 sm:tw-space-x-2 mt-4">
  
                                      <Button style={{ background: "#008066", fontSize: "14px", height: '35px' }} className="tw-bg-[#3eb981] mb-1 text-white px-2 py-1 me-2 rounded" disabled={true}>
                                        MY SAVINGS
                                      </Button>
                                    </div>
                                    <Box className="text-center justify-content-center align-items-center mt-4" sx={{}}>
                                      <strong className="text-black" style={{ color: '#03071E' }}>Calculate My Savings</strong>
                                      <Typography className="pb-1" sx={{ width: { xs: '100%', md: '50%' }, mx: 'auto', mt: 1, color: '#03071E' }}>
                                        How many jobs do you want to purchase
                                      </Typography>
                                      <Box sx={{ position: 'relative' }}>
                                        <TextField
                                          sx={{
                                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                            {
                                              borderColor: "gray", // Remove outline color on focus
                                            },
                                            '& .MuiOutlinedInput-input': {
                                              textAlign: 'center',
                                              backgroundColor: "white",
                                              height: '30px',
                                            },
                                            width: { xs: '100%', md: '50%' },
                                            marginBottom: "10px",
                                            marginTop: "10px",
                                            textAlign: "right",
                                            fontWeight: '900 !important',
                                          }}
                                          InputProps={{
                                            style: {
                                              fontSize: '30px',
                                              color: '#03071E99',
                                              fontWeight: 800,
                                            },
  
                                          }}
                                          InputLabelProps={{
  
                                            shrink: true,
                                          }}
                                          type="number"
                                          variant="outlined"
                                          value={selectedProjects}
                                          onChange={(e) => handleProjectChange(e.target.value)}
                                          onClick={() => { setShow000(false) }}
                                          className='center'
                                        />
                                        {
                                          show000 && selectedProjects.length === 0 &&
                                          <Box sx={{
                                            position: 'absolute', top: ' 50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)', zIndex: 0
                                          }}
                                            onClick={() => { setShow000(false) }}
                                          >
                                            <Typography sx={{
                                              fontSize: '30px',
                                              color: '#03071E99',
                                              fontWeight: 800,
                                            }}>000</Typography>
                                          </Box>
                                        }
                                      </Box>
                                    </Box>
  
                                    {selectedProjects >= 5 && selectedProjects <= 15 && (
  
                                      <span style={{ marginRight: "8px", borderRadius: "999px" }} className={`tw-mr-2 tw-rounded-[9999px] d-inline-flex`}>
                                        <CheckCircleIcon sx={{ color: "#3eb981" }} />
                                        <p style={{ marginLeft: "8px", width: "100%" }} className=" tw-ml-2 tw-w-full tw-text-base text-black">
                                          5 - 15 Jobs
                                        </p>
                                        <span style={{ background: "skyblue", fontSize: "12px", marginLeft: '8px' }} className="tw-bg-blue-400 tw-font-medium  flex-shrink-0 text-white px-2 py-1 tw-tracking-widest tw-text-sm rounded">
                                          -15% OFF
                                        </span>
                                      </span>
                                    )}
                                    {selectedProjects >= 16 && selectedProjects <= 34 && (
                                      <span style={{ marginRight: "8px", borderRadius: "999px" }} className={`tw-mr-2 tw-rounded-[9999px] d-inline-flex`}>
                                        <CheckCircleIcon sx={{ color: "#3eb981" }} />
                                        <p style={{ marginLeft: "8px", width: "100%" }} className=" tw-ml-2 tw-w-full tw-text-base text-black">
                                          16 - 34 Jobs
                                        </p>
                                        <span style={{ background: "skyblue", fontSize: "12px", marginLeft: '8px' }} className="tw-bg-blue-400 tw-font-medium  flex-shrink-0 text-white px-2 py-1 tw-tracking-widest tw-text-sm rounded">
                                          -25% OFF
                                        </span>
                                      </span>
                                    )}
                                    {selectedProjects >= 35 && selectedProjects <= 50 && (
                                      <span style={{ marginRight: "8px", borderRadius: "999px" }} className={`tw-mr-2 tw-rounded-[9999px] d-inline-flex`}>
                                        <CheckCircleIcon sx={{ color: "#3eb981" }} />
                                        <p style={{ marginLeft: "8px", width: "100%" }} className=" tw-ml-2 tw-w-full tw-text-base text-black">
                                          35 - 50 Jobs
                                        </p>
                                        <span style={{ background: "skyblue", fontSize: "12px", marginLeft: '8px' }} className="tw-bg-blue-400 tw-font-medium  flex-shrink-0 text-white px-2 py-1 tw-tracking-widest tw-text-sm rounded">
                                          -35% OFF
                                        </span>
                                      </span>
                                    )}
                                    {selectedProjects >= 51 && (
                                      <span style={{ marginRight: "8px", borderRadius: "999px" }} className={`tw-mr-2 tw-rounded-[9999px] d-inline-flex`}>
                                        <CheckCircleIcon sx={{ color: "#3eb981" }} />
                                        <p style={{ marginLeft: "8px", width: "100%" }} className=" tw-ml-2 tw-w-full tw-text-base text-black">
                                          51+ Jobs
                                        </p>
                                        <span style={{ background: "skyblue", fontSize: "12px", marginLeft: '8px' }} className="tw-bg-blue-400 tw-font-medium flex-shrink-0 text-white px-2 py-1 tw-tracking-widest tw-text-sm rounded">
  
                                          -50% OFF
                                        </span>
                                      </span>
                                    )}
                                    <div style={{ border: "1px solid", backgroundColor: 'white' }} className="tw-border tw-border-gray-400 mt-2 rounded justify-content-center align-items-center text-center">
                                      <p className="tw-text-3xl text-black font-bold tw-leading-none d-block align-items-center pb-1" style={{}}>
                                        <span style={{ fontSize: "26px", fontWeight: 900 }} className="font-bold">
                                          ${price.toLocaleString() ? price.toLocaleString() : "-"}
                                        </span>
                                        <span className="tw-text-base tw-ml-1 tw-font-normal tw-text-gray-500">
                                          /Year
                                        </span>
                                        <span style={{ color: "#008066", fontWeight: 600 }} className="d-block tw-text-base  tw-text-green-700">
                                          SAVE $
                                          {savings.toLocaleString() ? savings.toLocaleString() : ""}
                                        </span>
                                      </p>
                                    </div>
                                    <div style={{ height: "50px" }} className="h-10"></div>
                                  </>
                                  :
                                  <>
                                    <Typography align='center' sx={{ fontWeight: 'bold', mt: 8 }}> Do You Have Discount Code ? </Typography>
                                    <Typography align='center' sx={{ mb: 4 }}> if no then please click next. </Typography>
  
                                    <Box align='center' sx={{ backgroundColor: '#e9e9e9', p: 2, borderRadius: 2, mt: 2 }}>
                                      <Typography sx={{ marginBottom: '20px' }}>Enter Your Discount Code</Typography>
                                      <TextField fullWidth variant="outlined" outline='lightgray' placeholder='_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ '
                                        sx={{
                                          '& .MuiOutlinedInput-input': {
                                            textAlign: 'center',
                                            backgroundColor: 'white',
                                            color: "#8C8E8E",
                                            outline: 'none !important',
                                            fontSize: "24px",
                                            fontWeight: 600,
                                            borderRadius: '5px',
                                            py: 2.5,
                                          },
                                          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                          {
                                            borderColor: "lightgray", // Remove outline color on focus
                                            outline: 'none !important'
                                          },
                                        }}
                                        value={validateValueAnnual} onChange={(e) => setValidateValueAnnual(e.target.value)}
                                      />
                                    </Box>
                                    <Box align='center' my={4} sx={{}}>
                                      {
                                        !validateAnnual ?
                                          <Button fullWidth variant="contained" color='success' sx={{ textDecoration: 'none', width: { xs: '100%', md: '70%' } }}
                                            onClick={handleValidateAnnual}>Validate</Button>
                                          :
                                          <>
                                            <CheckCircleIcon sx={{ color: "#38cb38", display: "block", my: 2, fontSize: '30px' }} />
                                            <Button variant='contained' sx={{ textDecoration: 'none', width: { xs: '100%', md: '70%' }, backgroundColor: "lightgreen", '&:hover': { backgroundColor: 'lightgreen !important' } }}
                                              onClick={handleValidateAnnual}>Success</Button>
                                            {
                                              validateMonthlyPkg.amountType === '%' ?
                                                <Typography sx={{ color: '#00B28F', fontWeight: "bold", mt: 2 }}>{` Discount of ${validateMonthlyPkg.amount}% for ${validateMonthlyPkg.expiration ? validateMonthlyPkg.expiration + '' : 'every'} months will be applied.`}</Typography>
                                                :
                                                ''
                                            }
                                            {
                                              validateMonthlyPkg.amountType === "projects" ?
                                                <Typography sx={{ color: '#00B28F', fontWeight: "bold", mt: 2 }}>{`${validateMonthlyPkg.amount} Jobs will be free for ${validateMonthlyPkg.expiration ? validateMonthlyPkg.expiration + '' : 'every'} months.`}</Typography>
                                                :
                                                ''
                                            }
                                            {
                                              validateMonthlyPkg.amountType === "amount" ?
                                                <Typography sx={{ color: '#00B28F', fontWeight: "bold", mt: 2 }}>{`The amount of $ ${validateMonthlyPkg.amount} will be deducted from your total invoice.`}</Typography>
                                                :
                                                ''
                                            }
                                          </>
                                      }
  
                                    </Box>
                                    <Box height={260}></Box>
                                  </>
                                :
  
                                !thisPlaneAgainBtn ?
  
                                  !thisPlaneBtn ?
                                    validateAnnual ?
                                      <>
                                        <Box align='center'>
                                          <Typography sx={{ width: { xs: '100%', md: '60%' }, fontWeight: 600, fontSize: '16px', color: '#03071ECC' }}>Great! So the total number of Jobs you want to prepurchase is</Typography>
                                          <Box sx={{ maxWidth: '150px', backgroundColor: "white", border: '1px solid lightgray', p: '11px 24px', fontSize: '32px', fontWeight: 400, borderRadius: '5px', mt: 2 }}>
                                            {selectedProjects}
                                          </Box>
                                          <Box sx={{ mt: 2 }}>
                                            {selectedProjects >= 5 && selectedProjects <= 15 && (
  
                                              <span style={{ marginRight: "8px", borderRadius: "999px" }} className={`tw-mr-2 tw-rounded-[9999px] d-inline-flex`}>
                                                <CheckCircleIcon sx={{ color: "#3eb981" }} />
                                                <p style={{ marginLeft: "8px", width: "100%" }} className=" tw-ml-2 tw-w-full tw-text-base text-black">
                                                  5 - 15 Jobs
                                                </p>
                                                <span style={{ background: "skyblue", fontSize: "12px", marginLeft: '8px' }} className="tw-bg-blue-400 tw-font-medium  flex-shrink-0 text-white px-2 py-1 tw-tracking-widest tw-text-sm rounded">
                                                  -15% OFF
                                                </span>
                                              </span>
                                            )}
                                            {selectedProjects >= 16 && selectedProjects <= 34 && (
                                              <span style={{ marginRight: "8px", borderRadius: "999px" }} className={`tw-mr-2 tw-rounded-[9999px] d-inline-flex`}>
                                                <CheckCircleIcon sx={{ color: "#3eb981" }} />
                                                <p style={{ marginLeft: "8px", width: "100%" }} className=" tw-ml-2 tw-w-full tw-text-base text-black">
                                                  16 - 34 Jobs
                                                </p>
                                                <span style={{ background: "skyblue", fontSize: "12px", marginLeft: '8px' }} className="tw-bg-blue-400 tw-font-medium  flex-shrink-0 text-white px-2 py-1 tw-tracking-widest tw-text-sm rounded">
                                                  -25% OFF
                                                </span>
                                              </span>
                                            )}
                                            {selectedProjects >= 35 && selectedProjects <= 50 && (
                                              <span style={{ marginRight: "8px", borderRadius: "999px" }} className={`tw-mr-2 tw-rounded-[9999px] d-inline-flex`}>
                                                <CheckCircleIcon sx={{ color: "#3eb981" }} />
                                                <p style={{ marginLeft: "8px", width: "100%" }} className=" tw-ml-2 tw-w-full tw-text-base text-black">
                                                  35 - 50 Jobs
                                                </p>
                                                <span style={{ background: "skyblue", fontSize: "12px", marginLeft: '8px' }} className="tw-bg-blue-400 tw-font-medium  flex-shrink-0 text-white px-2 py-1 tw-tracking-widest tw-text-sm rounded">
                                                  -35% OFF
                                                </span>
                                              </span>
                                            )}
                                            {selectedProjects >= 51 && (
                                              <span style={{ marginRight: "8px", borderRadius: "999px" }} className={`tw-mr-2 tw-rounded-[9999px] d-inline-flex`}>
                                                <CheckCircleIcon sx={{ color: "#3eb981" }} />
                                                <p style={{ marginLeft: "8px", width: "100%" }} className=" tw-ml-2 tw-w-full tw-text-base text-black">
                                                  51+ Jobs
                                                </p>
                                                <span style={{ background: "skyblue", fontSize: "12px", marginLeft: '8px' }} className="tw-bg-blue-400 tw-font-medium flex-shrink-0 text-white px-2 py-1 tw-tracking-widest tw-text-sm rounded">
  
                                                  -50% OFF
                                                </span>
                                              </span>
                                            )}
                                          </Box>
                                          <Box sx={{ width: { xs: '100%', md: '90%' }, mt: 2, backgroundColor: 'white', borderRadius: 1, border: '1px solid gray', p: 1 }}>
                                            <Typography sx={{ fontWeight: 600, fontSize: '14px', color: '#03071E' }}>Your Total Today Is</Typography>
                                            <Typography sx={{ fontWeight: 900, fontSize: '32px', color: '#03071E', mt: 0.5 }}> $
                                              {
                                                validateMonthlyPkg?.amountType === '%' ?
                                                  (price - (price * validateMonthlyPkg?.amount / 100)).toFixed(2)
                                                  :
                                                  validateMonthlyPkg?.amountType === 'amount' ?
                                                    price - validateMonthlyPkg?.amount
                                                    :
                                                    price
                                              }</Typography>
                                            <Box>
                                              <Box align='left' sx={{ mx: 2 }}>
                                                <Typography sx={{ fontWeight: 700, fontSize: '14px', color: '#5600EB', mt: 0.5 }}>$ {price + savings} Standard Price</Typography>
                                                <Typography sx={{ fontWeight: 700, fontSize: '14px', color: '#008066', mt: 0.5 }}>- $ {savings} (Savings on
                                                  {selectedProjects >= 5 && selectedProjects <= 15 && (
                                                    ` 5 - 15 Jobs`
  
                                                  )}
                                                  {selectedProjects >= 16 && selectedProjects <= 34 && (
                                                    ` 16 - 34 Jobs`
  
  
                                                  )}
                                                  {selectedProjects >= 35 && selectedProjects <= 50 && (
                                                    ` 35 - 50 Jobs`
  
  
                                                  )}
                                                  {selectedProjects >= 51 && (
                                                    ` 51+ Jobs`
  
                                                  )}
                                                  )</Typography>
                                                {
                                                  validateMonthlyPkg?.amountType !== 'projects' &&
                                                  <Typography sx={{ fontWeight: 700, fontSize: '14px', color: '#008066', mt: 0.5 }}>
                                                    {
                                                      validateMonthlyPkg?.amountType === '%' ?
                                                        validateMonthlyPkg?.amount + '% '
                                                        :
                                                        validateMonthlyPkg?.amountType === 'amount' ?
                                                          "- $ " + validateMonthlyPkg?.amount + ' '
                                                          :
                                                          ""
                                                    }
                                                    (Coupon Code Discount)</Typography>
                                                }
                                              </Box>
                                            </Box>
  
                                          </Box>
                                          {
                                            validateMonthlyPkg?.amountType === "projects" ?
                                              <Typography sx={{ color: '#00B28F', fontWeight: "bold", mt: 2 }}>{`${validateMonthlyPkg.amount} Jobs will be free`}<br />{`for ${validateMonthlyPkg.expiration ? validateMonthlyPkg.expiration + '' : 'every'} months.`}</Typography>
                                              :
                                              ''
                                          }
                                          <Box sx={{ height: '100px' }}>
  
                                          </Box>
                                        </Box>
                                      </>
                                      :
                                      <Box align='center'>
  
                                        <Box align='center' sx={{ backgroundColor: '#711fff', borderRadius: 2, minHeight: '70px', alignItems: 'center', justifyContent: 'center' }}>
                                          <Box align='center' alignItems='center' textAlign='center' display='flex' sx={{ justifyContent: 'center' }}>
                                            <Typography style={{ fontSize: '35px', fontWeight: 600, color: 'white' }}>${price.toLocaleString() ? price.toLocaleString() : "-"} </Typography>
                                            <Typography sx={{ color: 'white', fontSize: '20px' }}> /{selectedProjects}Job /Year </Typography>
                                          </Box>
                                          <Typography display='block' sx={{ color: 'white', fontSize: '20px', mt: '-10px', mb: 1 }}>save $ {savings.toLocaleString() ? savings.toLocaleString() : ""}</Typography>
                                        </Box>
  
                                        <p className="d-flex align-items-center py-1 text-black mt-4 ">
                                          <span
                                            style={{ marginRight: "8px" }}
                                            className={` mr-2 text-gray-500 d-inline-flex align-items-center justify-content-center`}
                                          >
                                            <RemoveCircleIcon style={{ color: 'gray' }} />
                                          </span>
                                          15 to 50 % Off (5-51+ Jobs)
                                        </p>
  
                                        <p className="d-flex align-items-center py-1 text-black ">
                                          <span style={{ marginRight: "8px" }} className="mr-2 d-inline-flex align-items-center justify-content-center">
                                            <CheckCircleIcon sx={{ color: "#711fff" }} />
                                          </span>
                                          No Limitations
                                        </p>
                                        <p className="d-flex align-items-centen py-1 text-black " style={{ textAlign: 'left' }} >
                                          <span style={{ marginRight: "8px" }} className="mr-2  d-inline-flex align-items-center justify-content-center">
                                            <CheckCircleIcon sx={{ color: "#711fff" }} />
                                          </span>
                                          Additional Job billed at standard monthly rates
                                        </p>
  
                                        <Box height={370}></Box>
  
                                      </Box>
                                    :
                                    <Box align='center'>
  
                                      <Box align='center' sx={{ backgroundColor: '#711fff', borderRadius: 2, minHeight: '70px', alignItems: 'center', justifyContent: 'center', py: 1 }}>
                                        <Typography sx={{ fontWeight: 'bolder', color: 'white', fontSize: '16px' }}>You Will Be Charged</Typography>
                                        <Box align='center' alignItems='center' textAlign='center' display='flex' sx={{ justifyContent: 'center' }}>
                                          <Typography style={{ fontSize: '35px', fontWeight: 600, color: 'white' }}>${
                                            validateAnnual ?
  
                                              validateMonthlyPkg?.amountType === '%' ?
                                                (price - (price * validateMonthlyPkg?.amount / 100)).toFixed(2)
                                                :
                                                validateMonthlyPkg?.amountType === 'amount' ?
                                                  price - validateMonthlyPkg?.amount
                                                  :
                                                  price
  
                                              : price.toLocaleString() && price.toLocaleString()} </Typography>
                                          <Typography sx={{ color: 'white', fontSize: '20px' }}> /{selectedProjects}Job /Year </Typography>
                                        </Box>
                                        <Typography display='block' sx={{ color: 'white', fontSize: '20px', mt: '-10px', mb: 1 }}>save $ {savings.toLocaleString() ? savings.toLocaleString() : ""}</Typography>
                                      </Box>
                                      {
                                        validateMonthlyPkg.amountType === '%' ?
                                          <Typography sx={{ color: '#00B28F', fontWeight: "bold", mt: 2 }}>{` Discount of ${validateMonthlyPkg.amount}% for ${validateMonthlyPkg.expiration ? validateMonthlyPkg.expiration + '' : 'every'} months will be applied.`}</Typography>
                                          :
                                          ''
                                      }
                                      {
                                        validateMonthlyPkg.amountType === "projects" ?
                                          <Typography sx={{ color: '#00B28F', fontWeight: "bold", mt: 2 }}>{`${validateMonthlyPkg.amount} Jobs will be free for ${validateMonthlyPkg.expiration ? validateMonthlyPkg.expiration + '' : 'every'} months.`}</Typography>
                                          :
                                          ''
                                      }
                                      {
                                        validateMonthlyPkg.amountType === "amount" ?
                                          <Typography sx={{ color: '#00B28F', fontWeight: "bold", mt: 2 }}>{`The amount of $ ${validateMonthlyPkg.amount} will be deducted from your total invoice.`}</Typography>
                                          :
                                          ''
                                      }
  
                                      <Typography mt={20} fontWeight='bolder'>Are You Sure You Want To Switch to this plan</Typography>
  
                                      <Box align='center' sx={{ backgroundColor: '#e9e9e9', p: 2, borderRadius: 2, mt: 2, mb: 20 }}>
                                        <Typography sx={{ marginBottom: '20px' }}>Please type switch to conform </Typography>
                                        <TextField fullWidth variant="outlined" outline='lightgray' placeholder='switch'
                                          value={typeSwitch}
                                          onChange={(e) => changeTypeSwitch(e)}
                                          sx={{
                                            '& .MuiOutlinedInput-input': {
                                              textAlign: 'center',
                                              backgroundColor: 'white',
                                              height: '40px'
                                            },
  
                                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                            {
                                              borderColor: "gray", // Remove outline color on focus
                                            },
                                          }} />
                                      </Box>
                                    </Box>
                                  :
                                  <Box align='center'>
                                    <Box sx={{ backgroundColor: '#5600EB', color: 'white', borderRadius: 2, py: 2 }}>
                                      <Typography fontSize='14px'>YOU HAVE A TOTAL OF</Typography>
                                      <Box sx={{ backgroundColor: 'white', color: 'black', mx: 3, borderRadius: 2, outline: 'black' }}>
                                        <Typography fontSize={32}>{selectedProjects}</Typography>
                                        <Typography fontSize={14} fontWeight={500}>JOBS</Typography>
                                      </Box>
                                    </Box>
  
                                    <Typography fontSize={14} sx={{ color: 'red', my: 2 }}> PLAN VALID UNTIL {subscriptExpiry}</Typography>
                                    {validateMonthlyPkg?.code !== '' && validateMonthlyPkg?.code &&
                                      <Box align='center' sx={{ backgroundColor: '#5FCEA8', p: 2, borderRadius: 2, mt: 2 }}>
                                        <Typography sx={{ marginBottom: '20px', color: "black" }}>Coupon Code Used, <span style={{ fontWeight: '700' }}>
                                          {
                                            validateMonthlyPkg?.amountType === '%' ?
                                              validateMonthlyPkg?.amount + ' % '
                                              :
                                              validateMonthlyPkg?.amountType === 'amount' ?
                                                "$ " + validateMonthlyPkg?.amount + ' '
                                                :
                                                validateMonthlyPkg?.amount + " jobs "
                                          }
                                          Discount</span> </Typography>
                                        <TextField value={validateAnnual ? validateValueAnnual : validateMonthlyPkg?.code} readOnly={true} fullWidth id="outlined-basic" variant="outlined" outline='lightgray' placeholder='_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ ' sx={{
                                          '& .MuiOutlinedInput-input': {
                                            textAlign: 'center',
                                            backgroundColor: 'white',
                                            color: "#8C8E8E",
                                            // height: '20px',
                                            outline: 'none !important',
                                            fontSize: "24px",
                                            fontWeight: 600,
                                            borderRadius: '5px',
                                            py: 2.5,
  
                                          },
                                          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                          {
                                            borderColor: "lightgray", // Remove outline color on focus
                                            outline: 'none !important'
                                          },
                                        }} />
                                        <Typography sx={{ marginBottom: '20px', color: "black", fontWeight: '700', mt: 0.5 }}>Valid for {validateMonthlyPkg.expiration} months</Typography>
                                      </Box>
                                    }
                                    <div style={{ textAlign: 'left', marginTop: '20px' }}>
                                      {selectedProjects >= 5 && selectedProjects <= 15 && (  
                                        <span style={{ marginRight: "8px", borderRadius: "999px" }} className={`tw-mr-2 tw-rounded-[9999px] d-inline-flex`}>
                                          <CheckCircleIcon sx={{ color: "#3eb981" }} />
                                          <p style={{ marginLeft: "8px", width: "100%" }} className=" tw-ml-2 tw-w-full tw-text-base text-black">
                                            5 - 15 Jobs
                                          </p>
                                          <span style={{ background: "skyblue", fontSize: "12px", marginLeft: '10px' }} className="tw-bg-blue-400 tw-font-medium  flex-shrink-0 text-white px-2 py-1 tw-tracking-widest tw-text-sm rounded">
                                            -15% OFF
                                          </span>
                                        </span>
                                      )}
                                      {selectedProjects >= 16 && selectedProjects <= 34 && (
                                        <span style={{ marginRight: "8px", borderRadius: "999px" }} className={`tw-mr-2 tw-rounded-[9999px] d-inline-flex`}>
                                          <CheckCircleIcon sx={{ color: "#3eb981" }} />
                                          <p style={{ marginLeft: "8px", width: "100%" }} className=" tw-ml-2 tw-w-full tw-text-base text-black">
                                            16 - 34 Jobs
                                          </p>
                                          <span style={{ background: "skyblue", fontSize: "12px", marginLeft: '10px' }} className="tw-bg-blue-400 tw-font-medium  flex-shrink-0 text-white px-2 py-1 tw-tracking-widest tw-text-sm rounded">
                                            -25% OFF
                                          </span>
                                        </span>
                                      )}
                                      {selectedProjects >= 35 && selectedProjects <= 50 && (
                                        <span style={{ marginRight: "8px", borderRadius: "999px" }} className={`tw-mr-2 tw-rounded-[9999px] d-inline-flex`}>
                                          <CheckCircleIcon sx={{ color: "#3eb981" }} />
                                          <p style={{ marginLeft: "8px", width: "100%" }} className=" tw-ml-2 tw-w-full tw-text-base text-black">
                                            35 - 50 Jobs
                                          </p>
                                          <span style={{ background: "skyblue", fontSize: "12px", marginLeft: '10px' }} className="tw-bg-blue-400 tw-font-medium  flex-shrink-0 text-white px-2 py-1 tw-tracking-widest tw-text-sm rounded">
                                            -35% OFF
                                          </span>
                                        </span>
                                      )}
                                      {selectedProjects >= 51 && (
                                        <span style={{ marginRight: "8px", borderRadius: "999px" }} className={`tw-mr-2 tw-rounded-[9999px] d-inline-flex`}>
                                          <CheckCircleIcon sx={{ color: "#3eb981" }} />
                                          <p style={{ marginLeft: "8px", width: "100%" }} className=" tw-ml-2 tw-w-full tw-text-base text-black">
                                            51+ Jobs
                                          </p>
                                          <span style={{ background: "skyblue", fontSize: "12px", marginLeft: '10px' }} className="tw-bg-blue-400 tw-font-medium flex-shrink-0 text-white px-2 py-1 tw-tracking-widest tw-text-sm rounded">
  
                                            -50% OFF
                                          </span>
                                        </span>
                                      )}
  
                                    </div>
                                    <p className="d-flex align-items-center py-1 text-black mt-2">
                                      <span style={{ marginRight: "8px" }} className="mr-2 d-inline-flex align-items-center justify-content-center">
                                        <CheckCircleIcon sx={{ color: "#711fff" }} />
                                      </span>
                                      No Limitations
                                    </p>
                                    <p className="d-flex align-items-centen py-1 text-black " style={{ textAlign: 'left' }} >
                                      <span style={{ marginRight: "8px" }} className="mr-2  d-inline-flex align-items-center justify-content-center">
                                        <CheckCircleIcon sx={{ color: "#711fff" }} />
                                      </span>
                                      Additional Job billed at standard monthly rates
                                    </p>
                                    <Box sx={{ display: { xs: 'block', md: 'flex' }, justifyContent: 'center', my: 12 }}>
                                    </Box>
                                  </Box>
                            }
                          </Box>
  
                          <Box sx={{ display: { sx: 'block', md: 'flex' } }}>
                            {
                              !thisPlaneAgainBtn ?
                                !nextWithOutCoupon ?
                                  <>
                                    <button
                                      onClick={handleAnnualBtn}
                                      disabled={projectsValue === null || isNaN(projectsValue)}
                                      style={{ background: "#711fff", height: '55px' }}
                                      className=" d-flex align-items-center justify-content-center text-center mt-auto text-white tw-bg-[#711fff] tw-border-0 py-2 px-4 w-100 tw-focus:outline-none rounded">
                                      Next
                                    </button>  
                                    {next &&
                                      <Button textDecoration='none' color='error' sx={{ width: { xs: '100%', md: "50%" } }} onClick={handleCancelAnnual} >Cancel</Button>
                                    }
                                  </>
                                  :
                                  <>
                                    <button
                                      onClick={() => handleSwitchToThisPlaneBtn('annual')}
                                      disabled={thisPlaneBtn && typeSwitch != 'switch'}
                                      style={thisPlaneBtn && typeSwitch != 'switch' ? { background: "#A16BFF", height: '55px' } : { background: "#711fff", height: '55px' }}
                                      className=" d-flex align-items-center justify-content-center text-center mt-auto text-white tw-bg-[#711fff] tw-border-0 py-2 px-4 w-100 tw-focus:outline-none rounded">
                                      {
                                        annualLoadingIcon ?
                                          <Box style={{ width: '40px', height: '40px' }}>
                                            <PreLoader1 />
                                          </Box>
                                          :
                                          'Switch To This Plan'
                                      }
                                    </button>
  
                                    {next &&
                                      <Button textDecoration='none' color='error' sx={{ width: { xs: '100%', md: "50%" } }} onClick={handleCancelAnnual} >Cancel</Button>
                                    }
                                  </>
                                :
                                <Box align='center' sx={{ backgroundColor: '#F1F3F5', color: '#03071E66', borderRadius: 2, width: '100%', height: "55px", alignItems: 'center', display: "flex", justifyContent: 'center' }}>
                                  <Typography>Current Plan</Typography>
                                </Box>  
                            }
                          </Box>
                        </Box>
                      </Box>
                    </div>
                  </div>
                </div>
                <div className="tw-text-gray-600 mt-2 tw-body-font">
                  <h3 style={{ fontWeight: "500px" }} className="d-flex tw-text-2xl font-semibold text-black justify-content-center text-center align-items-center">
                    Frequently Asked Questions
                  </h3>
                  <p className="d-flex mt-2 text-black justify-content-center text-center align-items-center  text-base">
                    If you have any questions not answered in the FAQ please don't
                    hesitate to <a style={{ color: "#711fff", cursor: "pointer" }} className="tw-text-[#711fff] ms-1 tw-cursor-pointer">contact us</a>
                  </p>
                  <div className="container px-4 pt-5 pb-5 mx-auto">
                    <div style={{ border: "1px solid" }} className="d-flex align-items-center w-100 mx-auto rounded tw-border pb-5 mb-5 tw-border-gray-200 flex-column  tw-flex-col">
                      <div className="flex-grow position-relative text-center mt-2 sm:tw-mt-0">
                        <h5 className="d-flex w-100 align-items-center text-center  text-black position-relative justify-content-center tw-text-xl tw-title-font mt-3 tw-font-[500px] mb-1">
                          What's the key difference between the Standard and Annualized
                          Plan ?
                          <span
                            className="tw-absolute ms-3 tw-right-6"
                            onClick={toggleParagraph}
                            style={{ cursor: "pointer" }}
                          >
                            {isParagraphVisible ? (
                              <RemoveCircleIcon />
                            ) : (
                              <AddCircleIcon />
                            )}
                          </span>
                        </h5>
                        {isParagraphVisible ? (
                          <p className="tw-leading-relaxed d-flex flex-wrap px-3 w-100 justify-content-center text-center align-items-center text-black tw-text-base">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                            do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            Ut enim ad minim veniam, quis nostrud exercitation ullamco
                            laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                            irure dolor in reprehenderit in voluptate velit esse cillum
                            dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                            cupidatat non proident, sunt in culpa qui officia deserunt
                            mollit anim id est laborum.
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
          }
        </>
      </Bounce>
    );
  };

  export default YourPlan