import React from 'react';

import Offcanvas from 'react-bootstrap/Offcanvas';
import { Box, Typography } from '@mui/material';

import Vector from '../../../Assets/welcomeScreen/Vector.svg';

export default function WelcomeNewHelpVideo({
  showHelp,
  setShowHelp,
  clickData,
  setClickData,
  allVideos,
}) {
  return (
    <Offcanvas
      show={showHelp}
      onHide={() => setShowHelp(false)}
      scroll={false}
      backdrop
      placement="bottom"
      style={{
        height: 'max-content',
        zIndex: 99999999,
        maxHeight: '700px',
      }}
    >
      <button
        aria-label="Close"
        type="button"
        className="close-modal"
        onClick={() => setShowHelp(false)}
      />
      <Offcanvas.Header>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '95%',
            margin: '0 auto',
          }}
        >
          <Typography
            sx={{
              fontFamily: "'Open Sans', sans-serif",
              fontWeight: '600',
              fontSize: '2rem',
            }}
          >
            Welcome to the Lessons Video Section
          </Typography>
        </Box>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: '1rem',
          }}
        >
          <Box sx={{ width: { xs: '100%', md: '100%', lg: '65%' } }}>
            {clickData ? (
              <Box sx={{ padding: '0px 15px 0px 45px' }}>
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    position: 'relative',
                    background: 'black',
                    borderRadius: '15px',
                  }}
                >
                  <iframe
                    title="Video Player"
                    src={clickData.videoLink}
                    allowFullScreen
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    style={{
                      borderRadius: '15px',
                      width: '950px',
                      height: '450px',
                    }}
                  />
                </Box>

                <Box sx={{ width: '95%', margin: '0 auto' }}>
                  <Typography
                    sx={{
                      fontFamily: "'Open Sans', sans-serif",
                      fontWeight: '600',
                      fontSize: '1.5rem',
                      color: '#000',
                      marginTop: '1.5rem',
                    }}
                  >
                    {clickData.title}
                  </Typography>

                  <p
                    style={{
                      fontWeight: '400',
                      fontSize: '1.2rem',
                      marginTop: '1rem',
                    }}
                    // eslint-disable-next-line
                    dangerouslySetInnerHTML={{ __html: clickData?.para }}
                  />
                </Box>
              </Box>
            ) : null}
          </Box>

          <Box sx={{ width: '32%', padding: '0px 25px 0px 15px' }}>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                background: '#f7f7f7',
                maxHeight: '520px',
                minHeight: '420px',
                overflowY: 'auto',
                borderRadius: '15px',
                gap: '1rem',
              }}
            >
              <Box sx={{ width: '75%', margin: '0 auto' }}>
                {allVideos.map((videoItem) => (
                  <div key={videoItem.title}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: '20px',
                        fontWeight: 'bold',
                        color: 'var(--primary-color)',
                        padding: '0.5rem 0',
                      }}
                    >
                      {videoItem.title}
                    </Typography>

                    {videoItem.videosData.map((item) => (
                      <Box
                        key={item.title}
                        sx={{
                          display: 'flex',
                          position: 'relative',
                          width: '100%',
                          marginBottom: '10px',
                          overflow: 'hidden',
                          height: '90px',
                        }}
                      >
                        <Box
                          sx={{
                            width: '125px',
                            height: '80px',
                            position: 'relative',
                          }}
                        >
                          <img
                            alt={`Thumbnail for ${item.title}`}
                            src={item.img}
                            style={{
                              borderRadius: '8px',
                              width: '125px',
                              height: '80px',
                            }}
                          />
                          <img
                            src={Vector}
                            alt="Play Button"
                            style={{
                              position: 'absolute',
                              top: 25,
                              left: 50,
                              width: '30px',
                              height: '30px',
                            }}
                          />
                        </Box>

                        <Box
                          sx={{
                            background: 'none',
                            position: 'absolute',
                            width: '95%',
                            height: '120px',
                            left: '0',
                            top: '0',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setShowHelp(true);
                            setClickData(item);
                          }}
                        />

                        <Box
                          sx={{ width: '100%', padding: '4px 0px 4px 15px' }}
                        >
                          <Typography
                            component="div"
                            variant="h5"
                            sx={{
                              fontSize: '14px',
                              fontWeight: 600,
                              color: '#000000',
                            }}
                          >
                            {item.title}
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            color="#000000"
                            component="div"
                            sx={{
                              fontSize: '14px',
                              width: { sm: '100%', xl: '90%' },
                            }}
                          >
                            {item.para}
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                  </div>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
      </Offcanvas.Body>
    </Offcanvas>
  );
}
