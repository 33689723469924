import { ENDPOINT } from '../../pages/Opportunity/Redux/oppor';

// eslint-disable-next-line import/prefer-default-export
export async function createJobNote(accessToken, jobID, noteContent) {
  const response = await fetch(`${ENDPOINT}/api/project/note/create/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `JWT ${accessToken}`,
    },
    body: JSON.stringify({
      project_note: jobID,
      note: noteContent,
    }),
  });

  if (!response.ok) {
    const errorData = await response.json().catch(() => ({}));
    throw new Error(`Failed to create Job Note: ${errorData}`);
  }

  return response.json();
}
